import React, {createContext} from "react";
import {ChildrenType} from "./NavigationProvider";

const ApplicationProvider = ({children}: ChildrenType) => {
    const application = process.env.REACT_APP_BUILD as ApplicationType;

    const context: ApplicationContextType = {
        application,
    };

    return (
        <ApplicationContext.Provider value={context}>
            {children}
        </ApplicationContext.Provider>
    );
};

export default ApplicationProvider;

export const ApplicationContext = createContext({} as ApplicationContextType);

type ApplicationContextType = {
    application: ApplicationType
}

export type ApplicationType = "landing" | "admin"
