import {useEffect, useState} from "react";
import useGetOrder from "./useGetOrder";
import {SchematicType} from "config/schematics";
import {TemplateType} from "config/templates";
import {useSchemaTooltip} from "./useSchemaTooltip";
import {NavigationPageType} from "providers/NavigationProvider";

export const useGeneratorSchema = (id: number, name: NavigationPageType, schematic: SchematicType, template: TemplateType[]) => {
    const [schemaTemplates, setSchemaTemplates] = useState(template);

    const schemaElements = useSchemaTooltip(schematic, schemaTemplates, name);

    const [orderElements] = useGetOrder(id, schemaTemplates);

    useEffect(() => {
        setSchemaTemplates(orderElements);
    }, [orderElements]);

    return [schemaElements];
};
