import icons from "components/icons";
import React, {useContext} from "react";
import {NavigationContext} from "providers/NavigationProvider";
import {useTranslation} from "react-i18next";

const {IconChevronRight} = icons;

const Breadcrumbs = () => {
    const {t} = useTranslation();

    const {setNavigationPage, navigationPage} = useContext(NavigationContext);

    return (
        <div className="pochatok-im-breadcrumbs">
            <button
                onClick={() => setNavigationPage("pochatok")}
                className="pochatok-im-h3 pochatok-im-breadcrumbs__link"
            >
                {t("Market map")}
            </button>

            {
                navigationPage !== "pochatok" && <>
                    <span className="pochatok-im-breadcrumbs__separator">
                        <IconChevronRight/>
                    </span>

                    <button
                        disabled={true}
                        className="pochatok-im-h3 pochatok-im-breadcrumbs__link"
                    >
                        {t(navigationPage)}
                    </button>
                </>
            }
        </div>
    );
};

export default Breadcrumbs;
