import {useContext, useEffect, useState} from "react";
import {AdminApi} from "api";
import {MapContext} from "providers/MapProvider";
import {FilterContext} from "providers/FilterProvider";
import {ApplicationContext, ApplicationType} from "providers/ApplicationProvider";
import {TemplateType} from "config/templates";
import {FreePlace} from "components/Tooltip";
import i18next from "i18next";

const useGetOrder = (mainPlaceId: number, templates: TemplateType[]) => {
    const {application} = useContext(ApplicationContext);
    const {setStatusMap, setEquipment} = useContext(MapContext);
    const {categories} = useContext(FilterContext);
    const [orderElements, setOrderElements] = useState(templates);

    const getOrder = async () => {
        try {
            setStatusMap({loading: true, error: null});

            const filteredCategories = categories.filter(category => category.checked);
            const checkedCategories = filteredCategories.map(category => category.value);

            const lang = i18next.language === "ru" ? i18next.language : null;

            const response = await AdminApi.getOrder(mainPlaceId, checkedCategories, lang);

            const elements: TemplateType[] = templates.map(template => {
                const {id, content} = template;

                if (content === null) return template;

                const foundedItem = response.data.item?.find(item => item.name === id);

                if (foundedItem) {
                    if (application === "landing") {
                        const {is_active} = foundedItem;

                        const webflow_link = generateLink(application, "landing", "/sellers");

                        const content = !is_active ? {...FreePlace, webflow_link} : null;

                        return {...template, free: !is_active, content};
                    }

                    return {...template, color: foundedItem.color.toLowerCase()};
                }

                const foundedShop = response.data.shop?.find(shop => shop.name_place === id);

                const setFreeShopInfo = () => {
                    const webflow_link = generateLink(application, "landing", "/sellers");
                    const admin_link = generateLink(application, "admin", `/admin/order-map/create?type=1&main_place=${mainPlaceId}&name_place=${id}`);

                    return {
                        ...template,
                        content: {
                            ...FreePlace,
                            admin_link,
                            webflow_link,
                        },
                        free: true
                    };
                };

                if (foundedShop) {
                    const {is_filter, shop_name: name, shop_time_work, id, admin_link, status} = foundedShop;

                    if (status !== 1 && application === "landing") {
                        return setFreeShopInfo();
                    }

                    return {
                        ...template,
                        disabled: !is_filter,
                        content: {
                            name,
                            shop_id: id as number,
                            shop_time_work,
                            admin_link,
                        },
                        free: status !== 1 && application === "admin",
                    }
                } else {
                    return setFreeShopInfo();
                }
            });

            if (application === "admin") {
                const {equipment} = response.data;
                setEquipment(equipment ? equipment : null);
            }

            if (mainPlaceId === 5 && typeof response.data.meat_market_count === "number") {
                const {meat_market_count} = response.data;

                const newElements = generateRandomItemPlace(elements, application, meat_market_count);

                setOrderElements(newElements);
            } else {
                setOrderElements(elements);
            }

            setStatusMap({loading: false, error: null});
        } catch (error: any) {
            setStatusMap({loading: false, error: "Temporarily out of service"});
        }
    };

    useEffect(() => {
        getOrder();
    }, [categories]);

    return [orderElements];
};

export default useGetOrder;

const generateRandomItemPlace = (list: TemplateType[], application: ApplicationType, count: number): TemplateType[] => {
    if (count < 0) count = 0;
    if (count > 48) count = 48;

    const randomElement = generateRandomArray(count, 11, 58);

    return list.map((item, index) => {
        const name = item.id as string;

        if (name.includes("B") && !name.includes("BT")) {
            if (application === "landing") {
                if (randomElement.includes(index)) {
                    item.free = false;
                    item.content = {name: "Taken"};
                }
            }

            if (application === "admin") {
                item.content = {...item.content, admin_link: ""};

                if (randomElement.includes(index)) {
                    item.free = false;
                    item.content = {name: "Taken"};
                }
            }
        }

        return item;
    });
};

const generateRandomArray = (counter: number, min: number, max: number) => {
    const arr = [];
    const set = new Set(); // to keep track of duplicates
    while (arr.length < counter) {
        const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
        if (!set.has(randomNum)) { // add to array if not a duplicate
            arr.push(randomNum);
            set.add(randomNum);
        }
    }
    return arr;
}

export const generateLink = (application: ApplicationType, neededApplication: ApplicationType, link: string) => {
    return application === neededApplication ? link : undefined;
};
