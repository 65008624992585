import {TemplateType} from "config/templates";
import React, {createRef} from "react";
import {v4 as uuidv4} from "uuid";
import Tooltip from "components/Tooltip";
import Tippy from "@tippyjs/react";
import {followCursor} from "tippy.js";
import {SchemaElementType} from "./useSchemaTooltip";
import {TagType} from "config/tags";
import {NavigationPageType} from "../providers/NavigationProvider";

export const useTagTooltip = (tags: TagType[], templates: TemplateType[], place: NavigationPageType): SchemaElementType[] => {
    return templates.map(template => {
        const {disabled, id, content} = template;

        const tag = tags.find(tag => tag.tag === id);
        if (!tag) return {Element: null, Tooltip: null};

        const ref = createRef<HTMLDivElement>();

        const Element = <div
            key={uuidv4()}
            data-disabled={disabled}
            ref={ref}
            className={`pochatok-im-caption pochatok-im-photo__tag pochatok-im-tag pochatok-im-tag--border pochatok-im-tag--${tag.tag}`}
        >
            {tag.tag}
        </div>;

        const tooltipContent = content && <Tooltip place={place} content={content}/>;

        if (tooltipContent) {
            const Tooltip = <Tippy
                key={uuidv4()}
                appendTo={() => document.querySelector(".pochatok-im-photo") as Element}
                content={tooltipContent}
                interactive={true}
                plugins={[followCursor]}
                trigger="click"
                followCursor="initial"
                reference={ref}
            />

            return {Element, Tooltip};
        }

        return {Element, Tooltip: null};
    });
};
