export const ru = {
    translation: {
        "Product filters": "Фильтры товаров",
        "Clear filters": "Очистить Фильтр",
        "Search": "Поиск",
        "Scroll to view": "Прокрутите, чтобы просмотреть",
        "Symbols and notation": "Условные обозначения",
        "Equipment for rent": "Оборудование в аренде",
        "View equipment": "Смотреть оборудование",
        "Back": "Назад",
        "Schema": "Схема",
        "Photo": "Фото",
        "Market map": "Карта рынка",
        "Work mode": "режим работы",
        "More information": "Подробная информация",
        "Details": "Подробнее",
        "Free space for rent": "Свободное место для аренды",
        "pochatok": "Початок",
        "bacalia-market": "Бакалея",
        "covered-market": "Магазины под навесом",
        "fruits-vegetables-market": "Фруктово-овощной маркет",
        "fish-market": "Рыбный маркет",
        "gallery-food": "Бакалея",
        "manufactury-market": "Промышленный маркет",
        "meat-market": "Мясной маркет",
        "pavilion-odesa": "Навес «Одеса»",
        "pavilion-ukraine": "Навес «Україна»",
        "Restaurant Uha": "Ресторан «Уха»",
        "Administration": "Администрация",
        "Parking for cars": "Гостевая автопарковка для легкового транспорта",
        "Weighing": "Весовая",
        "Parking for trucks": "Гостевая автопарковка для грузового транспорта",
        "NovaPost": "«Нова Пошта»",
        "UkrPost": "«Укрпошта»",
        "Electric refueling": "Электрозаправка",
        "ATM/Terminal": "Банкомат/Терминал",
        "Terminal": "Терминал",
        "Toilet/Shower": "Туалет/Душ",
        "Toilet": "Туалет",
        "Outlets": "Торговые точки",
        "A place for pedestrians": "Место для пешеходов",
        "Place to stop the car": "Место для остановки авто",
        "Transportation": "Проезд",
        "Showcases": "Витрины",
        "Stores": "Магазины",
        "Pavilions": "Павильоны",
        "Cafe": "Кафе",
        "Bakery": "Пекарня",
        "Coffee": "Кофейня",
        "Refrigerators": "Холодильники",
        "Composition": "Склад",
        "Container washing": "Мойка тары",
        "Cleaning fish": "Чистка рыбы",
        "Veterinarian": "Ветеринар",
        "Reception": "Приемная",
        "Brama": "Брама",
        "Hallway": "Коридор",
        "Felling": "Рубка",
        "Fruits": "Фрукты",
        "Vegetables": "Овощи",
        "Greenery": "Зелень",
        "Meat": "Мясо",
        "Fish": "Рыба",
        "Seafood": "Морепродукты",
        "Smoked sausage products": "Копчено-колбасные изделия",
        "Dairy products": "Молочная продукция",
        "Bakery products": "Хлебобулочные изделия",
        "Alcoholic drinks": "Алкогольные напитки",
        "Soft drinks": "Безалкогольные напитки",
        "Pickles": "Соленья",
        "Nuts": "Орехи",
        "Dried fruits": "Сухофрукты",
        "Turkish sweets": "Турецкие сладости",
        "Sweets": "Сладости",
        "Pasta products": "Макаронные изделия",
        "Cereals": "Крупы",
        "Conservation": "Консервация",
        "Vegetable oil": "Растительное масло",
        "Spices": "Специи",
        "Tobacco products": "Табачные изделия",
        "Pet products": "Зоотовары",
        "Confectionery products": "Кондитерские изделия",
        "Hot drinks": "Горячие напитки",
        "Snacks": "Снеки",
        "Sauces": "Соусы",
        "Mushrooms": "Грибы",
        "Frozen foods and semi-finished products": "Замороженные продукты и полуфабрикаты",
        "Desserts": "Десерты",
        "Culinary": "Кулинария",
        "Baby food": "Детское питание",
        "Household chemicals": "Бытовая химия",
        "Personal hygiene": "Личная гигиена",
        "Cosmetics and care": "Косметика и уход",
        "Household goods": "Хозтовары",
        "Kitchen": "Кухня",
        "Hobbies and recreation": "Хобби и отдых",
        "Interior and textiles": "Интерьер и текстиль",
        "Chancellor's office": "Канцелярия",
        "Products for repair": "Товары для ремонта",
        "Household appliances": "Бытовая техника",
        "Goods for a holiday": "Товары для праздника",
        "Products for the garden and vegetable garden": "Товары для сада и огорода",
        "Bicycles": "Велосипеды",
        "Goods for construction": "Товары для строительства",
        "Furniture": "Мебель",
        "Tableware": "Посуда",
        "Lighting": "Освещение",
        "Plumbing": "Сантехника",
        "Instruments": "Инструменты",
        "Stationery": "Канцтовары",
        "Doors": "Двери",
        "Windows": "Окна",
        "Electronics": "Электроника",
        "Beauty and health": "Красота и здоровье",
        "Pharmacy": "Аптека",
        "Post Office": "Почта",
        "Place": "Место",
        "Please turn your phone over for better interaction with the map": "Пожалуйста, переверните свой телефон для лучшего взаимодействия с картой",
        "Nothing found.": "Ничего не найдено.",
        "Shop not found.": "Магазин не найдено.",
        "Assortment": "Ассортимент",
        "Contact information": "Контактная информация",
        "No information available": "Информация отсутсвует",
        "Taken": "Занято",
        "Temporarily out of service": "Временно не работает",
        "Something went wrong. Try again.": "Что-то пошло не так. Попробуйте еще раз.",
        "Full Name": "ФИО",
        "Email": "Электронная почта",
        "Phone": "Номер телефона",
        "What are you planning to implement?": "Что планируете реализовывать?",
        "Questions": "Вопросы",
        "Rent a place": "Арендовать место",
        "Send to": "Отправить",
        "Thank you! Your request has been sent.": "Спасибо! Ваша заявка была отправлена.",
        "Categories": "Категории"
    },
};
