import {EquipmentType} from "components/legend/LegendEquipment";
import React, {createContext, Dispatch, SetStateAction, useState} from "react";
import {StatusAsyncType} from "./FilterProvider";
import {ChildrenType} from "./NavigationProvider";

const MapProvider = ({children}: ChildrenType) => {
    const [equipment, setEquipment] = useState<EquipmentType | null>(null);
    const [statusMap, setStatusMap] = useState<StatusAsyncType>({loading: false, error: null});

    const context: MapContextType = {
        statusMap,
        setStatusMap,

        equipment,
        setEquipment,
    };

    return (
        <MapContext.Provider value={context}>
            {children}
        </MapContext.Provider>
    );
};

export default MapProvider;

export const MapContext = createContext({} as MapContextType);

type MapContextType = {
    statusMap: StatusAsyncType
    setStatusMap: Dispatch<SetStateAction<StatusAsyncType>>

    equipment: EquipmentType | null
    setEquipment: Dispatch<SetStateAction<EquipmentType | null>>
}
