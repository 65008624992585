import React, {createContext, Dispatch, SetStateAction, useState} from "react";
import {FilterOptionType} from "components/filter/Filter";
import {ChildrenType} from "./NavigationProvider";

const FilterProvider = ({children}: ChildrenType) => {
    const [categories, setCategories] = useState<FilterOptionType[]>([]);
    const [statusFilter, setStatusFilter] = useState<StatusAsyncType>({loading: true, error: null});

    const context: FilterContextType = {
        categories,
        setCategories,

        statusFilter,
        setStatusFilter,
    };

    return (
        <FilterContext.Provider value={context}>
            {children}
        </FilterContext.Provider>
    );
};

export default FilterProvider;

export const FilterContext = createContext({} as FilterContextType);

type FilterContextType = {
    categories: FilterOptionType[]
    setCategories: Dispatch<SetStateAction<FilterOptionType[]>>

    statusFilter: StatusAsyncType
    setStatusFilter: Dispatch<SetStateAction<StatusAsyncType>>
}

export type StatusAsyncType = {
    loading: boolean
    error: null | "Temporarily out of service" | "Shop not found."
}
