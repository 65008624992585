import React, {createContext, Dispatch, SetStateAction, useState} from "react";

const NavigationProvider = ({children}: ChildrenType) => {
    const [navigationPage, setNavigationPage] = useState<NavigationPageType>("pochatok");

    const context: NavigationContextType = {
        navigationPage,
        setNavigationPage,
    };

    return (
        <NavigationContext.Provider value={context}>
            {children}
        </NavigationContext.Provider>
    );
};

export default NavigationProvider;

export const NavigationContext = createContext({} as NavigationContextType);

type NavigationContextType = {
    navigationPage: NavigationPageType
    setNavigationPage: Dispatch<SetStateAction<NavigationPageType>>
}

export type NavigationPageType =
    "pochatok" |
    "pavilion-odesa" |
    "pavilion-ukraine" |
    "covered-market" |
    "fruits-vegetables-market" |
    "bacalia-market" |
    "fish-market" |
    "gallery-food" |
    "meat-market" |
    "manufactury-market"

export type ChildrenType = {
    children: JSX.Element | React.ReactNode
}
