import {RedirectSchemaParamsType} from "hooks/useRedirectSchema";

export type TargetType = {
    id: string
    params: RedirectSchemaParamsType
}

export const TargetsBacaliaMarket: TargetType[] = [
    {
        id: "fishTarget",
        params: {w: "120", h: "40", x: "230", y: "5", nav: "fish-market"},
    },
    {
        id: "galleryFoodTarget",
        params: {w: "40", h: "70", x: "534", y: "374", nav: "gallery-food"},
    },
    {
        id: "fruitsVegetablesTarget",
        params: {w: "40", h: "220", x: "4", y: "229", nav: "fruits-vegetables-market"},
    },
];

export const TargetsFishMarket: TargetType[] = [
    {
        id: "bacaliaTarget",
        params: {w: "60", h: "40", x: "193", y: "630", nav: "bacalia-market"},
    },
];

export const TargetsFruitsVegetablesMarket: TargetType[] = [
    {
        id: "bacaliaTarget",
        params: {w: "40", h: "60", x: "188", y: "496", nav: "bacalia-market"},
    },
];

export const TargetsGalleryFood: TargetType[] = [
    {
        id: "meatTarget",
        params: {w: "40", h: "125", x: "178", y: "225", nav: "meat-market"},
    },
    {
        id: "bacaliaTarget",
        params: {w: "35", h: "60", x: "2", y: "372", nav: "bacalia-market"},
    },
];

export const TargetsMeatMarket: TargetType[] = [
    {
        id: "galleryFoodTarget",
        params: {w: "40", h: "65", x: "4", y: "330", nav: "gallery-food"},
    },
];
