import React, {useContext} from "react";
import icons from "components/icons";
import {NavigationContext, NavigationPageType} from "providers/NavigationProvider";
import {useTranslation} from "react-i18next";
import {ApplicationContext} from "providers/ApplicationProvider";
import CaptionPlace from "./CaptionPlace";
import {PageContext} from "providers/PageProvider";
import {useSearchParams} from "react-router-dom";
import {ModalsContext} from "providers/ModalsProvider";
import {ContentTimeWork} from "pages/PageShop";
import i18next from "i18next";

const {IconArrowLeft, IconList} = icons;

type TooltipProps = {
    place: NavigationPageType
    content: TooltipContentType
}

const Tooltip = ({content, place}: TooltipProps) => {
    const {setModalFeedback} = useContext(ModalsContext);

    const {t} = useTranslation();
    const [, setSearchParams] = useSearchParams();
    const {application} = useContext(ApplicationContext);
    const {setPage} = useContext(PageContext);
    const {setNavigationPage} = useContext(NavigationContext);

    const {
        name,
        caption,
        navLink,
        admin_link,
        shop_id,
        webflow_link,
        shop_time_work,
        external_link
    } = content;

    const time = shop_time_work ? (shop_time_work as string).split("\r\n"): shop_time_work;

    const nameTitle = name === "free_place" ? t("Free space for rent") : name === "Taken" ? t(name) : name;

    const lang = i18next.language === "ru" ? "/ru" : "";

    return (
        <div className="pochatok-im-tooltip">
            <CaptionPlace caption={caption}/>

            {nameTitle && <h5 className="pochatok-im-tooltip__title pochatok-im-h5">{nameTitle}</h5>}

            {
                time && <div className="pochatok-im-tooltip__work">
                    <p className="pochatok-im-tooltip__work-caption pochatok-im-caption">{t("Work mode")}</p>
                    <ContentTimeWork time={time}/>
                </div>
            }

            {
                application === "landing" && webflow_link && <button
                    className="pochatok-im-button pochatok-im-button--default pochatok-im-tooltip__link"
                    onClick={() => setModalFeedback({open: true, place: place})}
                >
                    <span>{t("More information")}</span>
                    <IconArrowLeft/>
                </button>
            }

            {
                application === "landing" && shop_id && <button
                    className="pochatok-im-button pochatok-im-button--default pochatok-im-tooltip__link"
                    onClick={() => {
                        setSearchParams(`?shop_id=${shop_id}`);
                        setPage("shop");
                    }}
                >
                    <span>{t("More information")}</span>
                    <IconArrowLeft/>
                </button>
            }

            {
                external_link && <a
                    className="pochatok-im-button pochatok-im-button--default pochatok-im-tooltip__link"
                    target="_blank"
                    rel="noreferrer"
                    href={window.location.origin + lang + external_link}
                >
                    <span>{t("More information")}</span>
                    <IconArrowLeft/>
                </a>
            }

            {
                application === "admin" && admin_link && <a
                    className="pochatok-im-button pochatok-im-button--primary"
                    target="_blank"
                    rel="noreferrer"
                    href={window.location.origin + admin_link}
                >
                    <IconList/>
                    <span>{t("Details")}</span>
                </a>
            }

            {
                navLink && <button
                    className="pochatok-im-button pochatok-im-button--default pochatok-im-tooltip__link"
                    onClick={() => setNavigationPage(navLink as NavigationPageType)}
                >
                    <span>{t("More information")}</span>
                    <IconArrowLeft/>
                </button>
            }
        </div>
    );
};

export default Tooltip;

export const FreePlace: TooltipContentType = {
    name: "free_place",
};

export type TooltipContentType = {
    shop_id?: number
    name?: string | "free_place"
    shop_time_work?: string
    caption?: string | number
    navLink?: NavigationPageType
    webflow_link?: string
    admin_link?: string
    external_link?: string
}
