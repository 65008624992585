import React, {createContext, Dispatch, SetStateAction, useState} from "react";
import {ChildrenType, NavigationPageType} from "./NavigationProvider";
import ModalRotatePhone from "components/modals/ModalRotatePhone";
import ModalFeedback from "components/modals/ModalFeedback";
import ModalSuccessFeedback from "components/modals/ModalSuccessFeedback";

const ModalsProvider = ({children}: ChildrenType) => {
    const [modalFeedback, setModalFeedback] = useState<ModalFeedbackType>({open: false, place: "pochatok"});
    const [isModalSuccessFeedbackOpen, setIsModalSuccessFeedbackOpen] = useState(false);

    const context: ModalsContextType = {
        modalFeedback,
        setModalFeedback,

        isModalSuccessFeedbackOpen,
        setIsModalSuccessFeedbackOpen,
    };

    return (
        <ModalsContext.Provider value={context}>
            {children}

            <ModalSuccessFeedback/>
            <ModalFeedback/>
            <ModalRotatePhone/>
        </ModalsContext.Provider>
    );
};

export default ModalsProvider;

export const ModalsContext = createContext({} as ModalsContextType);

type ModalsContextType = {
    modalFeedback: ModalFeedbackType
    setModalFeedback: Dispatch<SetStateAction<ModalFeedbackType>>

    isModalSuccessFeedbackOpen: boolean
    setIsModalSuccessFeedbackOpen: Dispatch<SetStateAction<boolean>>
}

type ModalFeedbackType = {open: boolean, place: NavigationPageType}
