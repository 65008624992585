import {useRedirectSchema} from "hooks/useRedirectSchema";
import React from "react";
import {TargetsBacaliaMarket} from "config/targets";
import {SchematicBacaliaMarket} from "config/schematics";
import {SchemaType} from "./SchemaPochatok";

const SchemaBacaliaMarket = ({elements}: SchemaType) => {
    const targets = useRedirectSchema(SchematicBacaliaMarket, TargetsBacaliaMarket);

    return (
        <div className="pochatok-im-schema pochatok-im-schema--bacalia-market">
            <svg width="578" height="686" viewBox="0 0 578 686" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M46 686V46L532 46V487H338V686H46Z" fill="#DAE5DF"/>

                <path d="M265 215L265 203L283 203V215L265 215Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M247 125L247 118C247 111.925 251.925 107 258 107L265 107V125H247Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M301 125L301 118C301 111.925 296.075 107 290 107L283 107V125H301Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M301 197L301 204C301 210.075 296.075 215 290 215L283 215V197H301Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M247 197L247 204C247 210.075 251.925 215 258 215L265 215V197H247Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M477 375L477 376C477 382.075 481.925 387 488 387L531 387V375H477Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M495 119L495 107L507 107V119L495 119Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M477 347L477 335L489 335V347L477 347Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M477 307L477 295L489 295V307L477 307Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M477 267L477 255L489 255V267L477 267Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M477 227L477 215L489 215V227L477 227Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M477 187L477 175L489 175V187L477 187Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M477 147L477 135L489 135V147L477 147Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M335 241L335 234C335 227.925 339.925 223 346 223L353 223V241H335Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M335 289L335 296C335 302.075 339.925 307 346 307L353 307V289H335Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M443 241L443 234C443 227.925 438.075 223 432 223L425 223V241H443Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M443 289L443 296C443 302.075 438.075 307 432 307L425 307V289H443Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M379 307L379 295L399 295V307L379 307Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M379 235L379 223L399 223V235L379 235Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M390.694 264.985C391.376 264.655 391.783 264.072 391.783 263.291C391.783 262.015 390.727 261.3 388.945 261.3H385.359V269H389.154C391.101 269 392.146 268.175 392.146 266.833C392.146 265.898 391.629 265.26 390.694 264.985ZM387.13 262.62H388.791C389.561 262.62 389.968 262.95 389.968 263.555C389.968 264.171 389.561 264.49 388.791 264.49H387.13V262.62ZM389.088 267.68H387.13V265.733H389.088C389.913 265.733 390.342 266.041 390.342 266.69C390.342 267.361 389.913 267.68 389.088 267.68Z" fill="#29332D"/>
                <path d="M335 125L335 118C335 111.925 339.925 107 346 107L353 107V125H335Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M335 173L335 180C335 186.075 339.925 191 346 191L353 191V173H335Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M443 125L443 118C443 111.925 438.075 107 432 107L425 107V125H443Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M443 173L443 180C443 186.075 438.075 191 432 191L425 191V173H443Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M379 191L379 179L399 179V191L379 191Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M379 119L379 107L399 107V119L379 119Z" fill="#FAE9C8" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M390.694 148.985C391.376 148.655 391.783 148.072 391.783 147.291C391.783 146.015 390.727 145.3 388.945 145.3H385.359V153H389.154C391.101 153 392.146 152.175 392.146 150.833C392.146 149.898 391.629 149.26 390.694 148.985ZM387.13 146.62H388.791C389.561 146.62 389.968 146.95 389.968 147.555C389.968 148.171 389.561 148.49 388.791 148.49H387.13V146.62ZM389.088 151.68H387.13V149.733H389.088C389.913 149.733 390.342 150.041 390.342 150.69C390.342 151.361 389.913 151.68 389.088 151.68Z" fill="#29332D"/>

                {elements.map(el => el.Element)}
                {targets.map(el => el.Element)}

                <path d="M473 506V492" stroke="#93A39A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M466 499L473 492L480 499" stroke="#93A39A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

            {elements.map(el => el.Tooltip)}
        </div>
    );
};

export default SchemaBacaliaMarket;


