import icons from "components/icons";
import React from "react";
import {useTranslation} from "react-i18next";

const {IconRotatePhone} = icons;

const ModalRotatePhone = () => {
    const {t} = useTranslation();

    return (
        <div className="pochatok-im-modal">
            <div className="pochatok-im-modal__container">
                <div className="pochatok-im-modal__inner pochatok-im-modal__inner--rotate-phone">
                    <IconRotatePhone/>

                    <p>{t("Please turn your phone over for better interaction with the map")}</p>
                </div>
            </div>
        </div>
    );
};

export default ModalRotatePhone;
