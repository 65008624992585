import React from "react";
import {v4 as uuidv4} from "uuid";
import FilterOption from "./FilterOption";
import {FilterOptionType} from "./Filter";
import {useTranslation} from "react-i18next";

type FilterOptionsProps = {
    options: FilterOptionType[]
}

const FilterOptions = ({options}: FilterOptionsProps) => {
    const {t} = useTranslation();

    return (
        <>
            {
                options.length !== 0
                    ? <ul className="pochatok-im-options">
                        {
                            options.map(option => (
                                <li key={uuidv4()} className="pochatok-im-options__item">
                                    <FilterOption option={option}/>
                                </li>
                            ))
                        }
                    </ul>
                    : <p>{t("Nothing found.")}</p>
            }
        </>
    );
};

export default FilterOptions;
