import React from "react";
import {SchemaType} from "./SchemaPochatok";

const SchemaCoveredMarket = ({elements}: SchemaType) => {
    return (
        <div className="pochatok-im-schema pochatok-im-schema--covered-market">
            <svg width="72" height="756" viewBox="0 0 72 756" fill="none" xmlns="http://www.w3.org/2000/svg">
                {elements.map(el => el.Element)}
            </svg>

            {elements.map(el => el.Tooltip)}
        </div>
    );
};

export default SchemaCoveredMarket;



