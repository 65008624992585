import React, {useContext, useEffect, useState} from "react";
import ButtonCross from "components/ButtonCross";
import FieldInput from "components/FieldInput";
import {AdminApi, FeedbackType} from "api";
import {ModalsContext} from "providers/ModalsProvider";
import {useTranslation} from "react-i18next";

const initFeedback: FeedbackType = {
    form_id: "map_form",
    name: "",
    email: "",
    phone: "",
    realization: "",
    text: "",
};

const ModalFeedback = () => {
    const {t} = useTranslation();

    const {
        modalFeedback,
        setModalFeedback,
        setIsModalSuccessFeedbackOpen,
    } = useContext(ModalsContext);

    const [error, setError] = useState("");
    const [isDisabled, setIsDisabled] = useState(false);
    const [feedback, setFeedback] = useState<FeedbackType>(initFeedback);

    const handleChange = (key: keyof typeof feedback, value: string) => {
        setError("");
        setFeedback(prevState => ({...prevState, [key]: value}));
    };

    const sendFeedback = async () => {
        try {
            setIsDisabled(true);
            const response = await AdminApi.sendMapFeedback(feedback, modalFeedback.place);

            if (response.data) {
                setError("");
                handleClose();
                setIsModalSuccessFeedbackOpen(true);
                setFeedback(initFeedback);
            }
        } catch (e) {
            console.log(e);
            setError("Something went wrong. Try again.");
        } finally {
            setIsDisabled(false);
        }
    };

    const handleClose = () => setModalFeedback(prevState => ({...prevState, open: false}));

    useEffect(() => {
        const requiredFields = !feedback.email || !feedback.name || !feedback.phone || !feedback.realization;
        setIsDisabled(requiredFields);
    }, [feedback]);

    return (
        <div className={`pochatok-im-modal ${modalFeedback.open ? "show" : ""}`}>
            <div className="pochatok-im-modal__container">
                <div className="pochatok-im-modal__inner">
                    <ButtonCross
                        onClick={handleClose}
                        type="close"
                    />

                    <div className="pochatok-im-modal__header">
                        <h3 className="pochatok-im-h4">{t("Rent a place")}</h3>
                    </div>

                    <div className="pochatok-im-modal__body">
                        <FieldInput
                            type="input"
                            label={t("Full Name")}
                            value={feedback.name}
                            onChange={(e) => handleChange("name", e.target.value)}
                        />

                        <FieldInput
                            type="input"
                            label={t("Phone")}
                            placeholder="+38 (___) ___ __ __"
                            value={feedback.phone}
                            onChange={(e) => handleChange("phone", e.target.value)}
                        />

                        <FieldInput
                            type="input"
                            label={t("Email")}
                            value={feedback.email}
                            onChange={(e) => handleChange("email", e.target.value)}
                        />

                        <FieldInput
                            type="textarea"
                            label={t("What are you planning to implement?")}
                            value={feedback.realization}
                            onChange={(e) => handleChange("realization", e.target.value)}
                        />

                        <FieldInput
                            type="textarea"
                            label={t("Questions")}
                            value={feedback.text}
                            onChange={(e) => handleChange("text", e.target.value)}
                        />
                    </div>

                    <p className="pochatok-im-modal__error">{t(error)}</p>

                    <div className="pochatok-im-modal__footer">
                        <button
                            disabled={isDisabled}
                            onClick={sendFeedback}
                            className="pochatok-im-button pochatok-im-button--primary"
                        >
                            {t("Send to")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalFeedback;
