import axios, {AxiosResponse} from "axios";
import {EquipmentType} from "components/legend/LegendEquipment";

type AdminApiType = {
    _token: string
    _createAuthConfig: () => {
        headers: {
            Authorization: string
        }
    }
    getShopCategories: (lang: "ru" | null) => Promise<AxiosResponse<{ id: number, name: string }[]>>
    filterMainPlace: (id: number[]) => Promise<AxiosResponse<{ id: number, name: string }[]>>
    getOrder: (place_id: number, ids: number[], lang: "ru" | null) => Promise<AxiosResponse<OrderType>>
    getShop: (shop_id: string, lang: "ru" | null) => Promise<AxiosResponse<ShopContentType>>
    sendMapFeedback: (feedback: FeedbackType, place: string) => Promise<AxiosResponse<boolean>>
}

export const AdminApi: AdminApiType = {
    _token: process.env.REACT_APP_TOKEN as string,

    _createAuthConfig() {
        return {
            headers: {
                Authorization: `Bearer ${this._token}`,
            }
        }
    },

    async getShopCategories(lang) {
        const config = this._createAuthConfig();

        const langQuery = lang ? `?lang=${lang}` : "";

        return await axios.get(SHOP_CATEGORY_URL + langQuery, config);
    },

    async filterMainPlace(ids) {
        const config = this._createAuthConfig();

        const idsQuery = ids.map(id => `ids[]=${id}`).join("&");

        return await axios.get(FILTER_MAIN_PLACE_URL + `?${idsQuery}`, config);
    },

    async getOrder(order_id, ids, lang) {
        const config = this._createAuthConfig();

        const idsString = ids.map(id => `ids[]=${id}`).join("&");
        const idsQuery = idsString.length !== 0 ? "&" + idsString : "";
        const langQuery = lang ? `&lang=${lang}` : "";

        return await axios.get(ORDER_URL + `?place_id=${order_id}${idsQuery}${langQuery}`, config);
    },

    async getShop(shop_id, lang) {
        const config = this._createAuthConfig();

        const langQuery = lang ? `&lang=${lang}` : "";

        return await axios.get(SHOP_URL + `?id=${shop_id}${langQuery}`, config);
    },

    async sendMapFeedback(feedback, place) {
        const config = this._createAuthConfig();

        return await axios.post(SEND_MAP_FEEDBACK, {...feedback, place}, config);
    },
};

const apiUrl = `https://${process.env.REACT_APP_API_DOMAIN}/api/v2/`;
const orderMapUrl = "order-map/";

const SHOP_CATEGORY_URL = apiUrl + orderMapUrl + "shop-category";
const FILTER_MAIN_PLACE_URL = apiUrl + orderMapUrl + "filter-main-place";
const ORDER_URL = apiUrl + orderMapUrl + "order";
const SHOP_URL = apiUrl + orderMapUrl + "shop";
const SEND_MAP_FEEDBACK = apiUrl + orderMapUrl + "send-site-form";

type OrderType = {
    shop?: ShopOrderType[]
    item?: ItemOrderType[]
    equipment?: EquipmentType
    meat_market_count?: number
}

type ItemOrderType = {
    id: number | string
    name: string
    color: string
    is_active: boolean
}

type ShopOrderType = {
    id: number | string
    shop_name: string
    shop_time_work: string
    name_place: string
    is_filter: boolean
    admin_link: string
    webflow_link: string
    status: number
}

export type ShopContentType = {
    id: number
    name: string | null
    product: string | null
    category: string | null
    time_work: string | null | string[]
    name_place: string
    phone: string | null
    email: string | null
    instagram: string | null
    url: string | null
    image: string | null
    status: number
}

export type FeedbackType = {
    form_id: "map_form"
    name: string
    email: string
    phone: string
    realization: string
    text: string
}
