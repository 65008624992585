import LegendTags from "components/legend/LegendTags";
import PanelNavigation from "components/panel/PanelNavigation";
import React, {useContext} from "react";
import Loader from "components/Loader";
import icons from "components/icons";
import MapProvider, {MapContext} from "providers/MapProvider";
import LegendEquipment from "components/legend/LegendEquipment";
import SchemaBuilder, {SchemaBuilderType} from "./SchemaBuilder";
import {NavigationPageType} from "providers/NavigationProvider";
import {TagType} from "config/tags";
import {ApplicationContext} from "providers/ApplicationProvider";
import {useTranslation} from "react-i18next";

const {IconScroll} = icons;

export type PanelType = {
    name: NavigationPageType
    tags: TagType[]
    map?: JSX.Element
    builder?: SchemaBuilderType
}

const PanelBuilder = ({name, map, builder, tags}: PanelType) => {
    const {t} = useTranslation();
    const {application} = useContext(ApplicationContext)

    return (
        <MapProvider>
            <MapContext.Consumer>
                {
                    ({statusMap, equipment}) => <div className="pochatok-im-panel">
                        <div className={`pochatok-im-panel__map ${application === "admin" ? "admin" : ""}`}>
                            {
                                (name !== "pochatok" && application === "landing") &&
                                <PanelNavigation title={t(name)}/>
                            }

                            {
                                statusMap.error &&
                                <p style={{padding: 16}} className="pochatok-im-h4">{t(statusMap.error)}</p>
                            }

                            {map}

                            {builder && <SchemaBuilder name={name} builder={builder}/>}

                            {statusMap.loading && <Loader type="map"/>}

                            <div className="pochatok-im-panel__btn-scroll">
                                <button className="pochatok-im-button pochatok-im-button--scroll">
                                    <span>{t("Scroll to view")}</span>
                                    <IconScroll/>
                                </button>
                            </div>
                        </div>

                        <div className="pochatok-im-panel__legend">
                            <LegendTags tags={tags}/>

                            {
                                (application === "admin" && equipment) &&
                                <LegendEquipment count={equipment.count} link={equipment.link}/>
                            }
                        </div>
                    </div>
                }
            </MapContext.Consumer>
        </MapProvider>
    );
};

export default PanelBuilder;
