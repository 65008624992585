import React, {createRef} from "react";
import Tippy from "@tippyjs/react";
import {followCursor} from "tippy.js";
import Tooltip from "components/Tooltip";
import {v4 as uuidv4} from "uuid";
import {SchematicType} from "config/schematics";
import {TemplateType} from "config/templates";
import {NavigationPageType} from "../providers/NavigationProvider";

export const useSchemaTooltip = (schematic: SchematicType, templates: TemplateType[], place: NavigationPageType): SchemaElementType[] => {
    return templates.map(template => {
        const {id, disabled, content, free, color} = template;
        const svg = schematic[id]?.svg;
        if (!svg) return {Element: null, Tooltip: null};

        const ref = createRef<SVGSVGElement>();
        const Element = <g
            data-color={color}
            data-disabled={disabled}
            data-free={free}
            key={uuidv4()}
            ref={ref}
        >
            {
                content === null ? <g>{svg}</g>: svg
            }
        </g>;

        const tooltipContent = content && <Tooltip place={place} content={{...content, caption: id}}/>;

        if (tooltipContent) {
            const Tooltip = <Tippy
                key={uuidv4()}
                appendTo={() => document.querySelector(".pochatok-im-schema") as Element}
                content={tooltipContent}
                interactive={true}
                plugins={[followCursor]}
                trigger="click"
                followCursor="initial"
                reference={ref}
            />

            return {Element, Tooltip};
        }

        return {Element, Tooltip: null};
    });
};

export type SchemaElementType = { Element: JSX.Element | null, Tooltip: JSX.Element | null }
