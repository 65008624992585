import React, {ReactNode, useContext, useEffect, useState} from "react";
import "assets/styles/global.scss";
import RouterAdmin from "router/RouterAdmin";
import RouterLanding from "router/RouterLanding";
import {ApplicationContext} from "providers/ApplicationProvider";
import i18next from "i18next";
import PageMap from "./pages/PageMap";
import {PageContext} from "./providers/PageProvider";
import PageShop from "./pages/PageShop";
import {useQuery} from "hooks/useQuery";
import ModalsProvider from "./providers/ModalsProvider";

const App = () => {
    const {application} = useContext(ApplicationContext);

    const Landing = <AppWrapper mapRouter={<RouterLanding/>}/>;
    const Admin = <AppWrapper mapRouter={<RouterAdmin/>}/>;

    if (application === "admin") return Admin;

    return Landing;
};

export default App;

type AppWrapperProps = {
    mapRouter: ReactNode
}

const AppWrapper = ({mapRouter}: AppWrapperProps) => {
    const query = useQuery();
    const {page, setPage} = useContext(PageContext);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const paths = window.location.pathname.split("/");
        const lang = paths.find(path => path === "ru");

        if (lang) i18next.changeLanguage(lang);

        const isShop = query.has("shop_id");

        if (isShop) setPage("shop");

        setIsLoading(false);
    }, []);

    return (
        <div className="pochatok-im">
            <ModalsProvider>
                {
                    !isLoading && <>
                        {page === "map" && <PageMap router={mapRouter}/>}
                        {page === "shop" && <PageShop/>}
                    </>
                }
            </ModalsProvider>
        </div>
    );
};
