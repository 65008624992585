import {SchemaElementType} from "hooks/useSchemaTooltip";
import React from "react";
import {IconNdekc} from "../icons/IconNDEKC";

export type SchemaType = {
    elements: SchemaElementType[]
}

const SchemaPochatok = ({elements}: SchemaType) => {
    return (
        <div className="pochatok-im-schema pochatok-im-schema--pochatok">
            <svg width="700" height="700" viewBox="0 0 700 700" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M656 239.761V7M656 239.761H700M656 239.761V343.7M656 239.761H530M656 343.7V434.634C656 437.245 654.974 439.273 652.833 440.769L292.506 692.438C288.914 694.947 283.972 694.099 281.421 690.537L245.562 640.465M656 343.7L530 434.803M120 239.761L0 239.761M120 239.761L120 594.801C120 599.235 123.607 602.823 128.041 602.801L202 602.56M120 239.761H202M296 239.761H202M296 239.761L296 603.996M296 239.761L515 239.761M515 239.761L316.883 19.6481C315.366 17.9625 313.205 17 310.937 17H210C205.582 17 202 20.5817 202 25L202 239.761M515 239.761H530M202 239.761V602.56M202 602.56L245.562 640.465M296 603.996L245.562 640.465M296 603.996L530 434.803M530 239.761V434.803" stroke="#DAE5DF" strokeWidth="4" strokeLinecap="round"/>

                {elements.map(el => el.Element)}

                <rect x="634" y="267.965" width="56" height="20" rx="2" transform="rotate(-90 634 267.965)" fill="#F0F5F2"/>
                <path d="M643.885 251.411C643.555 250.729 642.972 250.322 642.191 250.322C640.915 250.322 640.2 251.378 640.2 253.16L640.2 256.746H647.9V252.951C647.9 251.004 647.075 249.959 645.733 249.959C644.798 249.959 644.16 250.476 643.885 251.411ZM641.52 254.975V253.314C641.52 252.544 641.85 252.137 642.455 252.137C643.071 252.137 643.39 252.544 643.39 253.314V254.975H641.52ZM646.58 253.017V254.975H644.633V253.017C644.633 252.192 644.941 251.763 645.59 251.763C646.261 251.763 646.58 252.192 646.58 253.017Z" fill="#29332D"/>
                <path d="M639.65 247.119C639.65 247.724 640.068 248.186 640.706 248.186C641.168 248.186 641.498 247.955 641.652 247.603L643.39 248.076V246.987L641.806 246.349C641.201 246.107 640.992 246.063 640.706 246.063C640.079 246.063 639.65 246.503 639.65 247.119Z" fill="#29332D"/>
                <path d="M639.518 243.999C639.518 243.57 639.21 243.24 638.759 243.24C638.308 243.24 638 243.57 638 243.999C638 244.428 638.308 244.769 638.759 244.769C639.21 244.769 639.518 244.428 639.518 243.999ZM639.518 241.777C639.518 241.348 639.21 241.007 638.759 241.007C638.308 241.007 638 241.348 638 241.777C638 242.195 638.308 242.536 638.759 242.536C639.21 242.536 639.518 242.195 639.518 241.777ZM647.9 243.779V241.997H640.2L640.2 243.779H647.9Z" fill="#29332D"/>
                <path d="M643.885 234.748C643.588 234.066 642.994 233.637 642.246 233.637C640.849 233.637 640.035 235.177 640.035 236.959C640.035 237.839 640.233 238.763 640.662 239.599L642.048 239.06C641.674 238.4 641.498 237.641 641.498 236.992C641.498 236.123 641.828 235.441 642.455 235.441C642.961 235.441 643.28 235.859 643.28 236.585V238.334H644.622V236.453C644.622 235.65 644.908 235.122 645.491 235.122C646.206 235.122 646.58 235.947 646.58 236.959C646.58 237.707 646.382 238.565 645.953 239.269L647.229 239.819C647.779 238.862 648.032 237.773 648.032 236.75C648.032 234.902 647.196 233.318 645.744 233.318C644.875 233.318 644.193 233.857 643.885 234.748Z" fill="#29332D"/>
                <path d="M646.448 224.043L640.2 224.043L640.2 229.961L642.609 230.049C644.765 230.126 646.415 230.379 646.448 231.38V231.688L649.495 231.699V230.049H647.9V224.615L649.495 224.615V222.965L646.448 222.965V224.043ZM642.774 228.476L641.652 228.443L641.652 225.803L646.448 225.803V229.246C645.821 228.718 644.413 228.542 642.774 228.476Z" fill="#29332D"/>

                <rect x="230.5" y="256.5" width="23" height="23" rx="11.5" fill="white"/>
                <path d="M236 266.5H248" stroke="#29332D" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M237.5 270.25L242 270.25" stroke="#29332D" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="235.75" y="263.25" width="12.5" height="9.5" rx="1.5" stroke="#29332D"/>
                <rect x="230.5" y="256.5" width="23" height="23" rx="11.5" stroke="#DAE5DF"/>
                <rect x="251.5" y="27.5" width="23" height="23" rx="11.5" fill="white"/>
                <path d="M263.643 33V37.6667H267.5L262.357 45V40.3333H258.5L263.643 33Z" stroke="#29332D" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="251.5" y="27.5" width="23" height="23" rx="11.5" stroke="#DAE5DF"/>
                <rect x="251.5" y="80.5" width="23" height="23" rx="11.5" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M263.086 85.25H262.914L262.751 85.3194L260.25 87.8264C260.142 87.9695 260.179 88.0779 260.357 88.0779H261.464C261.644 88.0779 261.786 88.2211 261.786 88.4011V90.2618C261.786 90.4418 261.93 90.5849 262.108 90.5849H263.928C264.143 90.5849 264.287 90.4418 264.287 90.2618V88.4011C264.287 88.2211 264.429 88.0779 264.607 88.0779H265.643C265.821 88.0779 265.858 87.9695 265.75 87.8264L263.251 85.3194L263.086 85.25ZM267.216 89.3316C267.162 89.26 267.108 89.234 267.068 89.247C267.027 89.26 267.001 89.3143 267.001 89.4032V94.5234C267.001 94.6145 267.027 94.6774 267.068 94.6991C267.108 94.7208 267.162 94.7034 267.216 94.6318L269.644 92.1964C269.786 92.0902 269.786 91.8733 269.644 91.7302L267.216 89.3316ZM258.999 89.1926C259.044 89.2142 259.072 89.2771 259.072 89.3682V94.5947C259.072 94.6858 259.044 94.74 258.999 94.7574C258.956 94.7747 258.894 94.7574 258.821 94.7032L256.356 92.1962C256.215 92.0899 256.215 91.873 256.356 91.7299L258.821 89.2598C258.894 89.1882 258.956 89.1709 258.999 89.1926ZM263.928 93.3067H262.108C261.93 93.3067 261.786 93.4498 261.786 93.6298V95.5989C261.786 95.8136 261.644 95.9568 261.464 95.9568H260.428C260.25 95.9568 260.179 96.063 260.323 96.1715L262.751 98.6416C262.821 98.7132 262.912 98.75 263 98.75C263.09 98.75 263.178 98.7132 263.251 98.6416L265.679 96.1715C265.821 96.063 265.75 95.9568 265.572 95.9568H264.607C264.429 95.9568 264.287 95.8136 264.287 95.5989V93.6298C264.287 93.4498 264.143 93.3067 263.928 93.3067Z" fill="#ED1C24"/>
                <rect x="251.5" y="80.5" width="23" height="23" rx="11.5" stroke="#DAE5DF"/>

                <rect x="295.5" y="80.5" width="23" height="23" rx="11.5" fill="white"/>
                <rect x="295.5" y="80.5" width="23" height="23" rx="11.5" stroke="#DAE5DF"/>
                <rect width="13.5" height="13.5" transform="translate(300.25 85.25)" fill="white"/>
                <path d="M311.371 92.9248C311.371 92.9247 306.901 91.9133 306.901 91.9133C305.569 91.6119 304.733 90.2875 305.035 88.9552C305.336 87.6229 306.661 86.7874 307.993 87.0888C309.325 87.3903 310.161 88.7147 309.859 90.0468C309.708 90.7138 309.301 91.2558 308.764 91.5935C308.764 91.5935 308.763 91.5946 308.763 91.5949C308.747 91.6062 308.737 91.6246 308.737 91.6454C308.737 91.6744 308.757 91.6988 308.784 91.7054C308.784 91.7054 310.772 92.155 310.772 92.155C310.82 92.1658 310.869 92.1475 310.898 92.1091C310.898 92.1088 310.898 92.109 310.898 92.109C311.446 91.3842 311.775 90.4799 311.775 89.5017C311.775 87.1982 309.975 85.3156 307.705 85.1819C307.685 85.1808 307.562 85.176 307.52 85.1754C306.515 85.1596 305.496 85.4478 304.61 86.0684C302.372 87.6354 301.828 90.7197 303.395 92.9576L307.371 98.6367C307.388 98.6598 307.416 98.6748 307.446 98.6748C307.477 98.6748 307.505 98.6595 307.522 98.6362C307.522 98.6362 311.427 93.0675 311.427 93.067C311.437 93.0523 311.443 93.0344 311.443 93.0152C311.443 92.9711 311.412 92.9343 311.371 92.9248Z" fill="#FABC26"/>

                <IconNdekc/>

                <rect x="471.5" y="309.5" width="23" height="23" rx="11.5" fill="white"/>
                <path d="M477 319.5H489" stroke="#29332D" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M478.5 323.25L483 323.25" stroke="#29332D" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="476.75" y="316.25" width="12.5" height="9.5" rx="1.5" stroke="#29332D"/>
                <rect x="471.5" y="309.5" width="23" height="23" rx="11.5" stroke="#DAE5DF"/>
                <rect x="545.5" y="371.5" width="23" height="23" rx="11.5" fill="white"/>
                <path d="M549.5 379.25L551.375 386L553.25 380.094L555.125 386L557 379.25" stroke="#29332D" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M564.5 381.359C564.5 380.8 564.27 380.263 563.859 379.868C563.449 379.472 562.893 379.25 562.312 379.25H561.437C560.857 379.25 560.301 379.472 559.891 379.868C559.48 380.263 559.25 380.8 559.25 381.359V383.891C559.25 384.45 559.48 384.987 559.891 385.382C560.301 385.778 560.857 386 561.437 386H562.312C562.893 386 563.449 385.778 563.859 385.382C564.27 384.987 564.5 384.45 564.5 383.891" stroke="#29332D" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="545.5" y="371.5" width="23" height="23" rx="11.5" stroke="#DAE5DF"/>
                <rect x="399.5" y="315.5" width="23" height="23" rx="11.5" fill="white"/>
                <path d="M405 325.5H417" stroke="#29332D" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M406.5 329.25L411 329.25" stroke="#29332D" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="404.75" y="322.25" width="12.5" height="9.5" rx="1.5" stroke="#29332D"/>
                <rect x="399.5" y="315.5" width="23" height="23" rx="11.5" stroke="#DAE5DF"/>
                <rect x="338.5" y="323.5" width="23" height="23" rx="11.5" fill="white"/>
                <path d="M344 333.5H356" stroke="#29332D" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M345.5 337.25L350 337.25" stroke="#29332D" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="343.75" y="330.25" width="12.5" height="9.5" rx="1.5" stroke="#29332D"/>
                <rect x="338.5" y="323.5" width="23" height="23" rx="11.5" stroke="#DAE5DF"/>
            </svg>

            {elements.map(el => el.Tooltip)}
        </div>
    );
};

export default SchemaPochatok;

