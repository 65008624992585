import React, {Dispatch, SetStateAction} from "react";
import icons from "components/icons";
import {useTranslation} from "react-i18next";

const {IconMap, IconImage} = icons;

type PanelTabsProps = {
    selected: TabsType
    setSelected: Dispatch<SetStateAction<TabsType>>
}

const PanelTabs = ({selected, setSelected}: PanelTabsProps) => {
    const {t} = useTranslation();

    return (
        <ul className="pochatok-im-panel__tabs">
            <li className="pochatok-im-panel__tab">
                <button
                    onClick={() => setSelected("schema")}
                    className={`pochatok-im-button pochatok-im-tab ${selected === "schema" ? "selected" : ""}`}
                >
                    <IconMap/>
                    <span>{t("Schema")}</span>
                </button>
            </li>

            <li className="pochatok-im-panel__tab">
                <button
                    onClick={() => setSelected("photo")}
                    className={`pochatok-im-button pochatok-im-tab ${selected === "photo" ? "selected" : ""}`}
                >
                    <IconImage/>
                    <span>{t("Photo")}</span>
                </button>
            </li>
        </ul>
    );
};

export default PanelTabs;

export type TabsType = "photo" | "schema"
