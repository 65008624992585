import React, {useContext, useEffect, useState} from "react";
import {PageContext} from "providers/PageProvider";
import {AdminApi, ShopContentType} from "api";
import {StatusAsyncType} from "providers/FilterProvider";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import Loader from "components/Loader";
import icons from "components/icons";
import CaptionPlace from "components/CaptionPlace";
import {useSearchParams} from "react-router-dom";
import {useQuery} from "hooks/useQuery";
import {v4 as uuidv4} from "uuid";

const PageShop = () => {
    const {t} = useTranslation();
    const {setPage} = useContext(PageContext);
    const [status, setStatus] = useState<StatusAsyncType>({loading: true, error: null});
    const [content, setContent] = useState<ShopContentType | null>(null);
    const [, setSearchParams] = useSearchParams();
    const query = useQuery();

    const {IconArrowLeft, IconInstagram} = icons;

    const getShop = async () => {
        try {
            setStatus({loading: true, error: null});

            const shopId = query.get("shop_id");

            if (!shopId) {
                setStatus({loading: false, error: "Shop not found."});
                return;
            }

            const lang = i18next.language;

            const response = await AdminApi.getShop(shopId, lang === "ru" ? lang : null);

            if (response.data.id) {
                const time = response.data.time_work;
                const content = {
                    ...response.data,
                    time_work: time ? (time as string).split("\r\n"): time,
                };

                setContent(content);
            } else {
                setStatus({loading: false, error: "Shop not found."});
                return;
            }

            setStatus({loading: false, error: null});
        } catch (error: any) {
            setStatus({loading: false, error: "Temporarily out of service"});
        }
    };

    useEffect(() => {
        getShop();
    }, []);

    return (
        <div className="pochatok-im-shop">
            <button
                className="pochatok-im-button pochatok-im-button--default pochatok-im-shop__btn-back"
                onClick={() => {
                    setPage("map");
                    setSearchParams("");
                }}
            >
                <IconArrowLeft/>
                {t("Market map")}
            </button>

            {status.error && <p className="pochatok-im-h4">{t(status.error)}</p>}
            {content && content.status !== 1 && <p className="pochatok-im-h4">{t("Shop not found.")}</p>}

            {status.loading && <Loader type="standard"/>}

            {!status.loading && !status.error && content && content.status === 1 && <div className="pochatok-im-shop__content">
                {
                    content?.image && <div className="pochatok-im-shop__image">
                        <img src={content.image} alt="магазин"/>
                    </div>
                }

                <div className="pochatok-im-shop__info">
                    <div className="pochatok-im-shop__card">
                        <div className="pochatok-im-shop__block">
                            {content?.name_place && <CaptionPlace caption={content.name_place}/>}

                            {
                                content?.name &&
                                <h4 className="pochatok-im-tooltip__title pochatok-im-h4">{content.name}</h4>
                            }
                        </div>


                        <div className="pochatok-im-shop__block">
                            <p className="pochatok-im-caption">{t("Assortment")}</p>

                            {
                                content?.product
                                    ? <p>{content.product}</p>
                                    : <p>{t("No information available")}</p>
                            }
                        </div>

                        <div className="pochatok-im-shop__block">
                            <p className="pochatok-im-caption">{t("Categories")}</p>

                            {
                                content?.category
                                    ? <p>{content.category}</p>
                                    : <p>{t("No information available")}</p>
                            }
                        </div>
                    </div>

                    <div className="pochatok-im-shop__card">
                        <div className="pochatok-im-shop__block">
                            <p className="pochatok-im-caption">{t("Work mode")}</p>

                            {
                                content?.time_work
                                    ? <ContentTimeWork time={content.time_work}/>
                                    : <p>{t("No information available")}</p>
                            }
                        </div>

                        <div className="pochatok-im-shop__block">
                            <p className="pochatok-im-caption">{t("Contact information")}</p>

                            {
                                !content?.phone && !content?.email && !content?.url && !content?.instagram
                                    ? <p>{t("No information available")}</p>
                                    : <>
                                        {
                                            content?.phone && <p>
                                                <a href={`tel:${content.phone}`}>{content.phone}</a>
                                            </p>
                                        }

                                        {
                                            content?.email && <p>
                                                <a href={`mailto:${content.email}`}>{content.email}</a>
                                            </p>
                                        }

                                        {
                                            content?.url && <p>
                                                <a target="_blank" rel="noreferrer" href={content.url}>{content.url}</a>
                                            </p>
                                        }

                                        <ul className="pochatok-im-shop__socials">
                                            {
                                                content?.instagram && <li>
                                                    <a target="_blank" rel="noreferrer" href={content.instagram}>
                                                        <IconInstagram/>
                                                        <span>Instagram</span>
                                                    </a>
                                                </li>
                                            }
                                        </ul>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
};

export default PageShop;

export const ContentTimeWork = ({time}: {time: string | string[]}) => {
    if (typeof time === "string") return <p>{time}</p>;
    return <ul>{time.map(item => <li key={uuidv4()}>{item}</li>)}</ul>;
};
