import icons, {IconsType} from "components/icons";
import {TagType} from "config/tags";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {NavigationContext, NavigationPageType} from "providers/NavigationProvider";
import i18next from "i18next";
import {ApplicationContext} from "../providers/ApplicationProvider";

type TagProps = {
    info: TagType
}

const Tag = ({info}: TagProps) => {
    const {setNavigationPage} = useContext(NavigationContext);
    const {application} = useContext(ApplicationContext);
    const {t} = useTranslation();
    const {tag, tagText, text} = info;
    const Icon = generateIcon(tag);
    const lang = i18next.language === "ru" ? "/ru" : "";

    return (
        <li>
            <span className={`pochatok-im-tag pochatok-im-tag--${tag} pochatok-im-caption`}>
                {Icon && <Icon/>}
                {tagText ? tagText : tag}
            </span>

            {
                info.navLink && <button
                    className="pochatok-im-legend-tag__link"
                    onClick={() => setNavigationPage(info.navLink as NavigationPageType)}
                >
                    {t(text)}
                </button>
            }

            {
                 info.externalLink && <>
                    {
                        application === "landing" ? <a
                            className="pochatok-im-legend-tag__link"
                            target="_blank"
                            rel="noreferrer"
                            href={window.location.origin + lang + info.externalLink}
                        >
                            {t(text)}
                        </a> : <p>{t(text)}</p>
                    }
                </>
            }

            {!info.navLink && !info.externalLink && <p>{t(text)}</p>}
        </li>
    );
};

export default Tag;

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const generateIcon = (name: string | number) => {
    if (typeof name === "number") return null;

    const capitalizedName = capitalizeFirstLetter(name);
    const iconName = ("Icon" + capitalizedName) as IconsType;
    return icons[iconName];
};
