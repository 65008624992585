import React, {useContext} from "react";
import {ApplicationContext} from "providers/ApplicationProvider";
import images from "components/images";
import {SchemaType} from "./SchemaPochatok";

const {ImagePochatok} = images;

const SchemaPhoto = ({elements}: SchemaType) => {
    const {application} = useContext(ApplicationContext);
    const uploadedImageSite = process.env.REACT_APP_WEBFLOW_PHOTO as string;
    const src = application === "landing" ? uploadedImageSite : ImagePochatok;

    return (
        <div className="pochatok-im-photo">
            <div className="pochatok-im-photo__wrapper">
                <img
                    width="763"
                    height="474"
                    src={src}
                    alt="початок"
                />

                <div className="pochatok-im-photo__tags">
                    {elements.map(el => el.Element)}
                </div>

                {elements.map(el => el.Tooltip)}
            </div>
        </div>
    );
};

export default SchemaPhoto;
