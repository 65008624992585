import React, {useContext} from "react";
import ButtonCross from "components/ButtonCross";
import {ModalsContext} from "providers/ModalsProvider";
import {useTranslation} from "react-i18next";

const ModalSuccessFeedback = () => {
    const {t} = useTranslation();

    const {isModalSuccessFeedbackOpen, setIsModalSuccessFeedbackOpen} = useContext(ModalsContext);

    return (
        <div className={`pochatok-im-modal ${isModalSuccessFeedbackOpen ? "show" : ""}`}>
            <div className="pochatok-im-modal__container">
                <div className="pochatok-im-modal__inner pochatok-im-modal__inner--alert">
                    <ButtonCross onClick={() => setIsModalSuccessFeedbackOpen(false)} type="close"/>

                    <div className="pochatok-im-modal__body">{t("Thank you! Your request has been sent.")}</div>
                </div>
            </div>
        </div>

    );
};

export default ModalSuccessFeedback;
