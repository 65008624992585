import React from "react";

type FieldInputProps = {
    value: string
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    label: string
    type: "input" | "textarea"
    placeholder?: string
}

const FieldInput = ({label, placeholder, value, onChange, type}: FieldInputProps) => {
    return (
        <label className="pochatok-im-fields">
            <span className="pochatok-im-fields__label pochatok-im-caption">{label}</span>
            {
                type === "input" &&
                <input value={value} onChange={onChange} placeholder={placeholder} className="pochatok-im-fields__input" type="text"/>
            }

            {
                type === "textarea" &&
                <textarea maxLength={255} value={value} onChange={onChange} placeholder={placeholder} className="pochatok-im-fields__input" rows={10} cols={10}/>
            }
        </label>
    );
};

export default FieldInput;
