import {ru} from "assets/langs/ru";
import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import {ua} from "./assets/langs/ua";

i18next
    .use(initReactI18next)
    .init({
        lng: "ua",
        resources: {
            ua,
            ru,
        },
    })
