import {useRedirectSchema} from "hooks/useRedirectSchema";
import React from "react";
import {SchematicMeatMarket} from "config/schematics";
import {TargetsMeatMarket} from "config/targets";
import {SchemaType} from "./SchemaPochatok";

const SchemaMeatMarket = ({elements}: SchemaType) => {
    const targets = useRedirectSchema(SchematicMeatMarket, TargetsMeatMarket);

    return (
        <div className="pochatok-im-schema pochatok-im-schema--meat-market">
            <svg width="298" height="646" viewBox="0 0 298 646" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M46 646V88H18V6H90V40H298V461H214V539H130V646H46Z" fill="#DAE5DF"/>

                {elements.map(el => el.Element)}
                {targets.map(el => el.Element)}

                <path d="M172 21V35" stroke="#93A39A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M179 28L172 35L165 28" stroke="#93A39A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M233 480V466" stroke="#93A39A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M226 473L233 466L240 473" stroke="#93A39A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

            {elements.map(el => el.Tooltip)}
        </div>
    );
};

export default SchemaMeatMarket;
