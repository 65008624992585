import React, {ReactNode, useContext} from "react";
import Breadcrumbs from "components/Breadcrumbs";
import Filter from "components/filter/Filter";
import Loader from "components/Loader";
import {useTranslation} from "react-i18next";
import {FilterContext} from "providers/FilterProvider";
import {ApplicationContext} from "providers/ApplicationProvider";

type PageMapProps = {
    router: ReactNode
}

const PageMap = ({router}: PageMapProps) => {
    const {t} = useTranslation();

    const {statusFilter} = useContext(FilterContext);
    const {application} = useContext(ApplicationContext);

    return (
        <div className="pochatok-im-map">
            {application === "admin" && <Breadcrumbs/>}
            {application === "landing" && <h2 className="pochatok-im-h2 pochatok-im-map__title">{t("Market map")}</h2>}

            <div className="pochatok-im-map__wrapper">
                <Filter/>

                {statusFilter.loading ? <Loader/>: router}
            </div>
        </div>
    );
};

export default PageMap;
