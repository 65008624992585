import React, {useContext} from "react";
import {NavigationContext, NavigationPageType} from "providers/NavigationProvider";
import {SchemaElementType} from "./useSchemaTooltip";
import {v4 as uuidv4} from "uuid";
import {TargetType} from "config/targets";
import {SchematicType} from "config/schematics";

export const useRedirectSchema = (
    schematic: SchematicType,
    targets: TargetType[],
): SchemaElementType[] => {
    const {setNavigationPage} = useContext(NavigationContext);

    return targets.map(target => {
        const {id, params} = target;
        const {w, h, x, y, nav} = params;
        const svg = schematic[id].svg;

        const Element = <g
            key={uuidv4()}
            style={{cursor: "pointer"}}
            onClick={() => setNavigationPage(nav)}
        >
            <rect width={w} height={h} x={x} y={y} fill="#fff"/>
            {svg}
        </g>;

        return {Element, Tooltip: null};
    });
};

export type RedirectSchemaParamsType = { w: string, h: string, x: string, y: string, nav: NavigationPageType }
