import React, {Dispatch, SetStateAction} from "react";
import icons from "components/icons";
import {useTranslation} from "react-i18next";
import ButtonCross from "../ButtonCross";

const {IconSearch, IconClose} = icons;

type FilterSearchProps = {
    search: string
    setSearch: Dispatch<SetStateAction<string>>
}

const FilterSearch = ({search, setSearch}: FilterSearchProps) => {
    const {t} = useTranslation();

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearch(value);
    };

    return (
        <label className="pochatok-im-search">
            <IconSearch/>

            <input
                className="pochatok-im-search__input"
                onChange={handleSearch}
                value={search}
                placeholder={t("Search") as string}
                type="text"
            />

            {
                search.length > 0 && <ButtonCross type="clear" onClick={() => setSearch("")}/>
            }
        </label>
    );
};

export default FilterSearch;
