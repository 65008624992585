import {useRedirectSchema} from "hooks/useRedirectSchema";
import React from "react";
import {SchemaType} from "./SchemaPochatok";
import {SchematicFruitsVegetablesMarket} from "config/schematics";
import {TargetsFruitsVegetablesMarket} from "config/targets";

const SchemaFruitsVegetablesMarket = ({elements}: SchemaType) => {
    const targets = useRedirectSchema(SchematicFruitsVegetablesMarket, TargetsFruitsVegetablesMarket);

    return (
        <div className="pochatok-im-schema pochatok-im-schema--fruits-vegetables-market">
            <svg width="232" height="726" viewBox="0 0 232 726" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 205H64V702H186V349.5L232 350V80.0001H185V104H126V62H18L18.0779 159.5H0V205Z" fill="#DAE5DF"/>

                {elements.map(el => el.Element)}
                {targets.map(el => el.Element)}

                <path d="M46 390H58" stroke="#93A39A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M52 384L58 390L52 396" stroke="#93A39A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M145 85V99" stroke="#93A39A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M152 92L145 99L138 92" stroke="#93A39A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M156 721V707" stroke="#93A39A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M149 714L156 707L163 714" stroke="#93A39A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

            {elements.map(el => el.Tooltip)}
        </div>
    );
};

export default SchemaFruitsVegetablesMarket;
