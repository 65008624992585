import {useRedirectSchema} from "hooks/useRedirectSchema";
import React from "react";
import {TargetsGalleryFood} from "config/targets";
import {SchematicGalleryFood} from "config/schematics";
import {SchemaType} from "./SchemaPochatok";

const SchemaGalleryFood = ({elements}: SchemaType) => {
    const targets = useRedirectSchema(SchematicGalleryFood, TargetsGalleryFood);

    return (
        <div className="pochatok-im-schema pochatok-im-schema--gallery-food">
            <svg width="214" height="576" viewBox="0 0 214 576" fill="none" xmlns="http://www.w3.org/2000/svg">
                {elements.map(el => el.Element)}
                {targets.map(el => el.Element)}

                <path d="M40 552L40 24L174 24L174 552H40Z" fill="#DAE5DF"/>
                <path d="M127 523L127 477L173 477V523L127 523Z" fill="#C2E6F2" stroke="#DAE5DF" strokeWidth="2"/>
                <path d="M148.423 496.3V497.73H149.963V504H151.745V496.3H148.423Z" fill="#29332D"/>
                <rect x="138.5" y="488.5" width="23" height="23" rx="11.5" fill="white"/>
                <path d="M142.5 496.25L144.375 503L146.25 497.094L148.125 503L150 496.25" stroke="#29332D" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M157.5 498.359C157.5 497.8 157.27 497.263 156.859 496.868C156.449 496.472 155.893 496.25 155.312 496.25H154.437C153.857 496.25 153.301 496.472 152.891 496.868C152.48 497.263 152.25 497.8 152.25 498.359V500.891C152.25 501.45 152.48 501.987 152.891 502.382C153.301 502.778 153.857 503 154.437 503H155.312C155.893 503 156.449 502.778 156.859 502.382C157.27 501.987 157.5 501.45 157.5 500.891" stroke="#29332D" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="138.5" y="488.5" width="23" height="23" rx="11.5" stroke="#DAE5DF"/>
                <path d="M107 5V19" stroke="#93A39A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M114 12L107 19L100 12" stroke="#93A39A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M107 571V557" stroke="#93A39A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M100 564L107 557L114 564" stroke="#93A39A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

            {elements.map(el => el.Tooltip)}
        </div>
    );
};

export default SchemaGalleryFood;
