export const ua = {
    translation: {
        "Product filters": "Фільтри товарів",
        "Clear filters": "Очистити Фильтр",
        "Search": "Пошук",
        "Scroll to view": "Прокрутіть, щоб переглянути",
        "Symbols and notation": "Умовні позначення",
        "Equipment for rent": "Обладнання в оренді",
        "View equipment": "Дивитися обладнання",
        "Back": "Назад",
        "Schema": "Схема",
        "Photo": "Фото",
        "Market map": "Карта ринку",
        "Work mode": "режим роботи",
        "More information": "Детальна інформація",
        "Details": "Детальніше",
        "Free space for rent": "Вільне місце для оренди",
        "pochatok": "Початок",
        "bacalia-market": "Бакалія",
        "covered-market": "Магазини під навісом",
        "fruits-vegetables-market": "Фруктово-овочевий маркет",
        "fish-market": "Рибний маркет",
        "gallery-food": "Бакалія",
        "manufactury-market": "Промисловий маркет",
        "meat-market": "М’ясний маркет",
        "pavilion-odesa": "Навіс «Одеса»",
        "pavilion-ukraine": "Навіс «Україна»",
        "Restaurant Uha": "Ресторан «Уха»",
        "Administration": "Адміністрація",
        "Parking for cars": "Гостьова автопарковка для легкового транспорту",
        "Weighing": "Вагова",
        "Parking for trucks": "Гостьова автопарковка для вантажного транспорту",
        "NovaPost": "«Нова Пошта»",
        "UkrPost": "«Укрпошта»",
        "Electric refueling": "Електрозаправка",
        "ATM/Terminal": "Банкомат/Термінал",
        "Terminal": "Термінал",
        "Toilet/Shower": "Туалет/Душ",
        "Toilet": "Туалет",
        "Outlets": "Торгові точки",
        "A place for pedestrians": "Місце для пішохідів",
        "Place to stop the car": "Місце для зупинки авто",
        "Transportation": "Проїзд",
        "Showcases": "Вітрини",
        "Stores": "Магазини",
        "Pavilions": "Павільйони",
        "Cafe": "Кафе",
        "Bakery": "Пекарня",
        "Coffee": "Кав’ярня",
        "Refrigerators": "Холодильники",
        "Composition": "Склад",
        "Container washing": "Мийка тари",
        "Cleaning fish": "Очистка риби",
        "Veterinarian": "Ветеринар",
        "Reception": "Приймальня",
        "Brama": "Брама",
        "Hallway": "Коридор",
        "Felling": "Рубка",
        "Fruits": "Фрукти",
        "Vegetables": "Овочі",
        "Greenery": "Зелень",
        "Meat": "М’ясо",
        "Fish": "Риба",
        "Seafood": "Морепродукти",
        "Smoked sausage products": "Копчено-ковбасні вироби",
        "Dairy products": "Молочна продукція",
        "Bakery products": "Хлібобулочні вироби",
        "Alcoholic drinks": "Напої алкогольні",
        "Soft drinks": "Напої безалкогольні",
        "Pickles": "Соління",
        "Nuts": "Горіхи",
        "Dried fruits": "Сухофрукти",
        "Turkish sweets": "Турецькі солодощі",
        "Sweets": "Солодощі",
        "Pasta products": "Макаронні вироби",
        "Cereals": "Крупи",
        "Conservation": "Консервація",
        "Vegetable oil": "Рослинна олія",
        "Spices": "Спеції",
        "Tobacco products": "Тютюнові вироби",
        "Pet products": "Зоотовари",
        "Confectionery products": "Кондитерські вироби",
        "Hot drinks": "Гарячі напої",
        "Snacks": "Снеки",
        "Sauces": "Соуси",
        "Mushrooms": "Гриби",
        "Frozen foods and semi-finished products": "Заморожені продукти та напівфабрикати",
        "Desserts": "Десерти",
        "Culinary": "Кулінарія",
        "Baby food": "Дитяче харчування",
        "Household chemicals": "Побутова хімія",
        "Personal hygiene": "Особиста гігієна",
        "Cosmetics and care": "Косметика та догляд",
        "Household goods": "Господарські товари",
        "Kitchen": "Кухня",
        "Hobbies and recreation": "Хобі та відпочинок",
        "Interior and textiles": "Інтер’єр та текстиль",
        "Chancellor's office": "Канцелярія",
        "Products for repair": "Товари для ремонту",
        "Household appliances": "Побутова техніка",
        "Goods for a holiday": "Товари для свята",
        "Products for the garden and vegetable garden": "Товари для саду та городу",
        "Bicycles": "Велосипеди",
        "Goods for construction": "Товари для будівництва",
        "Furniture": "Меблі",
        "Tableware": "Посуд",
        "Lighting": "Освітлення",
        "Plumbing": "Сантехніка",
        "Instruments": "Інструменти",
        "Stationery": "Канцтовари",
        "Doors": "Двері",
        "Windows": "Вікна",
        "Electronics": "Електроніка",
        "Beauty and health": "Краса та здоров’я",
        "Pharmacy": "Аптека",
        "Post Office": "Пошта",
        "Place": "Місце",
        "Please turn your phone over for better interaction with the map": "Будь ласка, переверніть свій телефон для кращої взаємодії з картою",
        "Nothing found.": "Нічого не знайдено.",
        "Shop not found.": "Магазин не знайдено.",
        "Assortment": "Асортимент",
        "Contact information": "Контактна інформація",
        "No information available": "Інформація відсутня",
        "Taken": "Зайнято",
        "Temporarily out of service": "Тимчасово не працює",
        "Something went wrong. Try again.": "Щось трапилося не так. Спробуй ще раз.",
        "Full Name": "ПІБ",
        "Email": "Електрона пошта",
        "Phone": "Номер телефону",
        "What are you planning to implement?": "Що плануєте реалізовувати?",
        "Questions": "Питання",
        "Rent a place": "Орендувати місце",
        "Send to": "Відправити",
        "Thank you! Your request has been sent.": "Дякуємо! Вашу заявку було відправлено.",
        "Categories": "Категорії"
    },
};
