import React, {useContext} from "react";
import PanelBuilder, {PanelType} from "components/builder/PanelBuilder";
import {v4 as uuidv4} from "uuid";
import {NavigationContext} from "providers/NavigationProvider";
import SchemaGalleryFood from "components/schemas/SchemaGalleryFood";
import {
    TagsCoveredMarket,
    TagsFruitsVegetablesMarket,
    TagsGalleryFood,
    TagsPavilionOdesa,
    TagsPavilionUkraine,
    TagsPochatok
} from "config/tags";
import {
    SchematicCoveredMarket,
    SchematicFruitsVegetablesMarket,
    SchematicPavilionOdesa,
    SchematicPavilionUkraine
} from "config/schematics";
import {
    TemplateCoveredMarket,
    TemplateFruitsVegetablesMarket,
    TemplatePavilionOdesa,
    TemplatePavilionUkraine
} from "config/templates";
import SchemaPavilionOdesa from "components/schemas/SchemaPavilionOdesa";
import SchemaPavilionUkraine from "components/schemas/SchemaPavilionUkraine";
import SchemaCoveredMarket from "components/schemas/SchemaCoveredMarket";
import SchemaFruitsVegetablesMarket from "components/schemas/SchemaFruitsVegetablesMarket";
import PanelPochatok from "components/panel/PanelPochatok";

type RouterProps = {
    pages: PanelType[]
}

export const defaultPages: PanelType[] = [
    {
        name: "pochatok",
        map: <PanelPochatok/>,
        tags: TagsPochatok,
    },
    {
        name: "fruits-vegetables-market",
        builder: {
            id: 1,
            schematic: SchematicFruitsVegetablesMarket,
            template: TemplateFruitsVegetablesMarket,
            Schema: SchemaFruitsVegetablesMarket,
        },
        tags: TagsFruitsVegetablesMarket,
    },
    {
        name: "covered-market",
        builder: {
            id: 10,
            schematic: SchematicCoveredMarket,
            template: TemplateCoveredMarket,
            Schema: SchemaCoveredMarket,
        },
        tags: TagsCoveredMarket,
    },
    {
        name: "pavilion-ukraine",
        builder: {
            id: 11,
            schematic: SchematicPavilionUkraine,
            template: TemplatePavilionUkraine,
            Schema: SchemaPavilionUkraine,
        },
        tags: TagsPavilionUkraine,
    },
    {
        name: "pavilion-odesa",
        builder: {
            id: 12,
            schematic: SchematicPavilionOdesa,
            template: TemplatePavilionOdesa,
            Schema: SchemaPavilionOdesa,
        },
        tags: TagsPavilionOdesa,
    },
    {
        name: "gallery-food",
        map: <SchemaGalleryFood elements={[]}/>,
        tags: TagsGalleryFood,
    },
];

const Router = ({pages}: RouterProps) => {
    const {navigationPage} = useContext(NavigationContext);

    return (
        <>
            {
                pages.map(page => {
                    const {name} = page;

                    const element = navigationPage === name ? <PanelBuilder {...page}/> : null;

                    return <React.Fragment key={uuidv4()}>{element}</React.Fragment>
                })
            }
        </>
    );
};

export default Router;
