import React from "react";
import {PanelType} from "components/builder/PanelBuilder";
import {
    TemplateBacaliaMarket,
    TemplateFishMarket,
    TemplateManufacturyMarket,
    TemplateMeatMarket,
} from "config/templates";
import {
    TagsBacaliaMarket,
    TagsFishMarket,
    TagsManufacuryMarket,
    TagsMeatMarket,
} from "config/tags";
import {
    SchematicBacaliaMarket,
    SchematicFishMarket,
    SchematicManufacturyMarket,
    SchematicMeatMarket,
} from "config/schematics";
import SchemaFishMarket from "components/schemas/SchemaFishMarket";
import SchemaBacaliaMarket from "components/schemas/SchemaBacaliaMarket";
import SchemaMeatMarket from "components/schemas/SchemaMeatMarket";
import SchemaManufacturyMarket from "components/schemas/SchemaManufacturyMarket";
import Router, {defaultPages} from "./Router";

const pages: PanelType[] = [
    ...defaultPages,
    {
        name: "fish-market",
        builder: {
            id: 2,
            schematic: SchematicFishMarket,
            template: TemplateFishMarket,
            Schema: SchemaFishMarket,
        },
        tags: TagsFishMarket,
    },
    {
        name: "bacalia-market",
        builder: {
            id: 3,
            schematic: SchematicBacaliaMarket,
            template: TemplateBacaliaMarket,
            Schema: SchemaBacaliaMarket,
        },
        tags: TagsBacaliaMarket,
    },
    {
        name: "meat-market",
        builder: {
            id: 5,
            schematic: SchematicMeatMarket,
            template: TemplateMeatMarket,
            Schema: SchemaMeatMarket,
        },
        tags: TagsMeatMarket,
    },
    {
        name: "manufactury-market",
        builder: {
            id: 14,
            schematic: SchematicManufacturyMarket,
            template: TemplateManufacturyMarket,
            Schema: SchemaManufacturyMarket,
        },
        tags: TagsManufacuryMarket,
    },
];

const RouterLanding = () => {
    return (
        <Router pages={pages}/>
    );
};

export default RouterLanding;
