import {NavigationPageType} from "providers/NavigationProvider";
import {externalLink} from "config/externalLink";

export type TagType = {
    tag: string | number
    text: string
    navLink?: NavigationPageType
    tagText?: string
    externalLink?: string
}

export const TagsPhoto: TagType[] = [
    {
        tag: 1,
        text: "fruits-vegetables-market",
        navLink: "fruits-vegetables-market",
    },
    {
        tag: 2,
        text: "fish-market",
        navLink: "fish-market",
    },
    {
        tag: 3,
        text: "bacalia-market",
        navLink: "bacalia-market",
    },
    {
        tag: 4,
        text: "bacalia-market",
        navLink: "gallery-food",
    },
    {
        tag: 5,
        text: "meat-market",
        navLink: "meat-market",
    },
    {
        tag: 6,
        text: "Restaurant Uha",
        externalLink: externalLink.Restaurant,
    },
    {
        tag: 7,
        text: "Administration",
        externalLink: externalLink.Administration,
    },
    {
        tag: 8,
        text: "Parking for cars",
        externalLink: externalLink.Parking,
    },
    {
        tag: 9,
        text: "Weighing",
    },
    {
        tag: 10,
        text: "covered-market",
        navLink: "covered-market",
    },
    {
        tag: 11,
        text: "pavilion-ukraine",
        navLink: "pavilion-ukraine",
    },
    {
        tag: 12,
        text: "pavilion-odesa",
        navLink: "pavilion-odesa",
    },
    {
        tag: 13,
        text: "Parking for trucks",
        externalLink: externalLink.ParkingTrucks,
    },
    {
        tag: 14,
        text: "manufactury-market",
        navLink: "manufactury-market",
    }
];

export const TagsPochatok: TagType[] = [
    ...TagsPhoto,
    {
        tag: "novapost",
        text: "NovaPost",
    },
    {
        tag: "ukrpost",
        text: "UkrPost",
    },
    {
        tag: "electro",
        text: "Electric refueling",
    },
    {
        tag: "terminal",
        text: "ATM/Terminal",
    },
    {
        tag: "wc",
        text: "Toilet/Shower",
    },
];

export const TagsGalleryFood: TagType[] = [
    {
        tag: "wc",
        text: "Toilet",
    },
];

export const TagsPavilionOdesa: TagType[] = [
    {
        tag: "market-place",
        text: "Outlets",
    },
    {
        tag: "pedestrians-place",
        text: "A place for pedestrians",
    },
    {
        tag: "parking",
        text: "Place to stop the car",
    },
    {
        tag: "driveway",
        text: "Transportation",
    },
];

export const TagsPavilionUkraine: TagType[] = [
    ...TagsPavilionOdesa,
];

export const TagsBacaliaMarket: TagType[] = [
    {
        tag: "bacalia",
        tagText: "в",
        text: "Showcases",
    },
    {
        tag: "bacalia",
        tagText: "м",
        text: "Stores",
    },
    {
        tag: "bacalia",
        tagText: "п",
        text: "Pavilions",
    },
];

export const TagsFishMarket: TagType[] = [
    {
        tag: "cafe",
        text: "Cafe"
    },
    {
        tag: "fish",
        tagText: "м",
        text: "Stores",
    },
];

export const TagsMeatMarket: TagType[] = [
    {
        tag: "meat",
        tagText: "м",
        text: "Stores",
    },
];

export const TagsCoveredMarket: TagType[] = [
    {
        tag: "cafe",
        text: "Cafe",
    },
];

export const TagsFruitsVegetablesMarket: TagType[] = [
    {
        tag: "coffee",
        text: "Coffee",
    },
    {
        tag: "bakery",
        text: "Bakery",
    },
];

export const TagsManufacuryMarket: TagType[] = [
    {
        tag: "cafe",
        text: "Cafe"
    },
    {
        tag: "novapost",
        text: "NovaPost",
    },
    {
        tag: "ukrpost",
        text: "UkrPost",
    },
];
