import {ReactComponent as IconImage} from "assets/icons/image.svg";
import {ReactComponent as IconMap} from "assets/icons/map.svg";
import {ReactComponent as IconSearch} from "assets/icons/search.svg";
import {ReactComponent as IconClose} from "assets/icons/close.svg";
import {ReactComponent as IconArrowLeft} from "assets/icons/arrow-left.svg";
import {ReactComponent as IconRotatePhone} from "assets/icons/rotate-phone.svg";
import {ReactComponent as IconFilter} from "assets/icons/filter.svg";
import {ReactComponent as IconScroll} from "assets/icons/scroll.svg";
import {ReactComponent as IconEdit} from "assets/icons/edit.svg";
import {ReactComponent as IconList} from "assets/icons/list.svg";
import {ReactComponent as IconChevronRight} from "assets/icons/chevron-right.svg";
import {ReactComponent as IconNovapost} from "assets/icons/novapost.svg";
import {ReactComponent as IconUkrpost} from "assets/icons/ukrpost.svg";
import {ReactComponent as IconElectro} from "assets/icons/electro.svg";
import {ReactComponent as IconTerminal} from "assets/icons/terminal.svg";
import {ReactComponent as IconCafe} from "assets/icons/cafe.svg";
import {ReactComponent as IconCoffee} from "assets/icons/coffee.svg";
import {ReactComponent as IconBakery} from "assets/icons/bakery.svg";
import {ReactComponent as IconWc} from "assets/icons/wc.svg";
import {ReactComponent as IconDriveway} from "assets/icons/driveway.svg";
import {ReactComponent as IconParking} from "assets/icons/parking-place.svg";
import {ReactComponent as IconFacebook} from "assets/icons/facebook.svg";
import {ReactComponent as IconInstagram} from "assets/icons/instagram.svg";

const index = {
    IconImage,
    IconMap,
    IconSearch,
    IconClose,
    IconArrowLeft,
    IconRotatePhone,
    IconFilter,
    IconScroll,
    IconEdit,
    IconList,
    IconChevronRight,
    IconNovapost,
    IconUkrpost,
    IconElectro,
    IconTerminal,
    IconCafe,
    IconCoffee,
    IconBakery,
    IconWc,
    IconDriveway,
    IconParking,
    IconFacebook,
    IconInstagram,
};

export default index;

export type IconsType = keyof typeof index
