import React from "react";
import {useTranslation} from "react-i18next";

type CaptionPlaceProps = {
    caption?: string | number
}

const CaptionPlace = ({caption}: CaptionPlaceProps) => {
    const {t} = useTranslation();

    return (
        <>
            {
                caption && typeof caption !== "number" && <p className="pochatok-im-caption">
                    {t("Place")} {t(caption) ? t(caption) : caption}
                </p>
            }
        </>
    );
};

export default CaptionPlace;
