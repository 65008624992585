import {TagsBacaliaMarket, TagsFishMarket, TagsManufacuryMarket, TagsMeatMarket, TagType} from ".";

export const TagsBacaliaMarketAdmin: TagType[] = [
    ...TagsBacaliaMarket,
    {
        tag: "bacalia",
        tagText: "пр",
        text: "Reception",
    },
    {
        tag: "bacalia",
        tagText: "х",
        text: "Refrigerators",
    },
    {
        tag: "bacalia",
        tagText: "с",
        text: "Composition",
    },
];

export const TagsFishMarketAdmin: TagType[] = [
    ...TagsFishMarket,
    {
        tag: "fish",
        tagText: "х",
        text: "Refrigerators",
    },
    {
        tag: "fish",
        tagText: "с",
        text: "Composition",
    },
    {
        tag: "fish",
        tagText: "м.т",
        text: "Container washing",
    },
    {
        tag: "fish",
        tagText: "о.р",
        text: "Cleaning fish",
    },
];

export const TagsMeatMarketAdmin: TagType[] = [
    ...TagsMeatMarket,
    {
        tag: "meat",
        tagText: "в",
        text: "Veterinarian",
    },
    {
        tag: "meat",
        tagText: "пр",
        text: "Reception",
    },
    {
        tag: "meat",
        tagText: "р",
        text: "Felling",
    },
    {
        tag: "meat",
        tagText: "к",
        text: "Hallway",
    },
    {
        tag: "meat",
        tagText: "х",
        text: "Refrigerators",
    },
];

export const TagsManufacuryMarketAdmin: TagType[] = [
    ...TagsManufacuryMarket,
    {
        tag: "manufactury",
        tagText: "х",
        text: "Refrigerators",
    },
    {
        tag: "manufactury",
        tagText: "б",
        text: "Brama",
    },
];
