import React from "react";
import {useGeneratorSchema} from "hooks/useGeneratorSchemaElements";
import {SchematicType} from "config/schematics";
import {TemplateType} from "config/templates";
import {SchemaType} from "components/schemas/SchemaPochatok";
import {NavigationPageType} from "providers/NavigationProvider";

type SchemaBuilderProps = {
    builder: SchemaBuilderType
    name: NavigationPageType
}

const SchemaBuilder = ({builder, name}: SchemaBuilderProps) => {
    const {Schema, id, schematic, template} = builder;
    const [elements] = useGeneratorSchema(id, name, schematic, template);

    return (
        <Schema elements={elements}/>
    );
};

export default SchemaBuilder;

export type SchemaBuilderType = {
    id: number
    schematic: SchematicType
    Schema: ({elements}: SchemaType) => JSX.Element
    template: TemplateType[]
}
