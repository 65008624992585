import React, {useContext} from "react";
import icons from "components/icons";
import {NavigationContext} from "providers/NavigationProvider";
import {useTranslation} from "react-i18next";

const {IconArrowLeft} = icons;

type PanelNavigationProps = {
    title: string
}

const PanelNavigation = ({title}: PanelNavigationProps) => {
    const {t} = useTranslation();
    const {setNavigationPage} = useContext(NavigationContext);

    return (
        <div className="pochatok-im-panel__nav">
            <button
                onClick={() => setNavigationPage("pochatok")}
                className="pochatok-im-button pochatok-im-button--default pochatok-im-panel__btn-back"
            >
                <IconArrowLeft/>
                <span>{t("Back")}</span>
            </button>

            <h4 className="pochatok-im-h4">{t(title)}</h4>
        </div>
    );
};

export default PanelNavigation;
