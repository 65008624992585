import React from 'react';
import icons from "./icons";

type ButtonCrossProps = {
    onClick: () => void
    type: "close" | "clear"
}

const ButtonCross = ({onClick, type}: ButtonCrossProps) => {
    const {IconClose} = icons;

    return (
        <button onClick={onClick} className="pochatok-im-button pochatok-im-button--close">
            <span>{type}</span>
            <IconClose/>
        </button>
    );
};

export default ButtonCross;
