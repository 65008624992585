import React from "react";
import {useTranslation} from "react-i18next";

const LegendEquipment = ({count, link}: EquipmentType) => {
    const {t} = useTranslation();

    return (
        <div className="pochatok-im-legend-equipment">
            <div className="pochatok-im-legend-equipment__wrapper">
                <div>
                    <p className="pochatok-im-legend-equipment__title">{t("Equipment for rent")}</p>
                    <span>{count}</span>
                </div>

                <a
                    href={window.location.origin + link}
                    target="_blank"
                    rel="noreferrer"
                    className="pochatok-im-button pochatok-im-legend-equipment__link pochatok-im-button--primary"
                >
                    {t("View equipment")}
                </a>
            </div>
        </div>
    );
};

export default LegendEquipment;

export type EquipmentType = { count: number, link: string }
