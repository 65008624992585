import React, {createContext, Dispatch, SetStateAction, useState} from "react";
import {ChildrenType} from "./NavigationProvider";

const PageProvider = ({children}: ChildrenType) => {
    const [page, setPage] = useState<PageType>("map");

    const context: PageContextType = {
        page,
        setPage,
    };

    return (
        <PageContext.Provider value={context}>
            {children}
        </PageContext.Provider>
    );
};

export default PageProvider;

export const PageContext = createContext({} as PageContextType);

type PageContextType = {
    page: PageType
    setPage: Dispatch<SetStateAction<PageType>>
}

export type PageType = "map" | "shop"
