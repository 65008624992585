import React, {useContext, useEffect, useState} from "react";
import FilterSearch from "components/filter/FilterSearch";
import {FilterContext} from "providers/FilterProvider";
import icons from "components/icons";
import {useTranslation} from "react-i18next";
import FilterOptions from "./FilterOptions";
import {AdminApi} from "api";
import i18next from "i18next";

const {IconFilter, IconClose} = icons;

const Filter = () => {
    const {t} = useTranslation();
    const {categories, setCategories, setStatusFilter, statusFilter} = useContext(FilterContext);

    const [search, setSearch] = useState("");
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState<FilterOptionType[]>([]);

    const getShopCategories = async () => {
        try {
            setStatusFilter({loading: true, error: null});

            const lang = i18next.language === "ru" ? i18next.language : null;

            const categories = await AdminApi.getShopCategories(lang);

            const dataCategories = categories.data.map(category => {
                const {id, name} = category;
                return {value: id, label: name, checked: false};
            });

            setCategories(dataCategories);
            setFilteredOptions(dataCategories);

            setStatusFilter({loading: false, error: null});
        } catch (error: any) {
            setStatusFilter({loading: false, error: "Temporarily out of service"});
        }
    };

    const handleClearFilter = () => {
        setCategories(categories.map(category => ({...category, checked: false})));
        setSearch("");
    };

    useEffect(() => {
        getShopCategories();
    }, []);

    useEffect(() => {
        document.body.classList[isFilterOpen ? "add" : "remove"]("overflow-hidden");
    }, [isFilterOpen]);

    useEffect(() => {
        search
            ? setFilteredOptions(categories.filter(category => {
                return category.label.toLowerCase().includes(search.toLowerCase());
            }))
            : setFilteredOptions(categories);
    }, [search, categories]);

    return (
        <>
            <button
                onClick={() => setIsFilterOpen(true)}
                className="pochatok-im-button pochatok-im-button--primary pochatok-im-button--filter pochatok-im-map__btn-filter"
            >
                <IconFilter/>
                <span>{t("Product filters")}</span>
            </button>

            <div className={`pochatok-im-filter ${isFilterOpen ? "open" : ""}`}>
                <div className="pochatok-im-filter__header">
                    <h4 className="pochatok-im-filter__title pochatok-im-h4">
                        {t("Product filters")}
                    </h4>

                    <span className="pochatok-im-filter__counter pochatok-im-caption">
                            {categories.filter(category => category.checked).length}
                    </span>

                    <button
                        className="pochatok-im-button pochatok-im-button--close"
                        onClick={() => setIsFilterOpen(false)}
                    >
                        <IconClose/>
                    </button>
                </div>

                <div className="pochatok-im-filter__body">
                    <div className="pochatok-im-filter__body-wrapper">
                        <div className="pochatok-im-filter__search">
                            <FilterSearch search={search} setSearch={setSearch}/>
                        </div>

                        {categories.length !== 0 && <FilterOptions options={filteredOptions}/>}

                        {
                            statusFilter.error &&
                            <p style={{marginTop: 16}} className="pochatok-im-h4">{t(statusFilter.error)}</p>
                        }
                    </div>
                </div>

                <div className="pochatok-im-filter__footer">
                    <div className="pochatok-im-filter__footer-buttons">
                        <button
                            onClick={handleClearFilter}
                            className="pochatok-im-button"
                        >
                            {t("Clear filters")}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Filter;

export type FilterOptionType = {
    value: number
    label: string
    checked: boolean
}
