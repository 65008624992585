import React from "react";
import {TagType} from "config/tags";
import Tag from "components/Tag";
import {v4 as uuidv4} from "uuid";
import {useTranslation} from "react-i18next";

type LegendTagsProps = {
    tags: TagType[]
}

const LegendTags = ({tags}: LegendTagsProps) => {
    const {t} = useTranslation();

    return (
        <div className="pochatok-im-legend-tag">
            <p className="pochatok-im-legend-tag__description">{t("Symbols and notation")}</p>

            <ul className="pochatok-im-legend-tag__list">
                {
                    tags.map(item => <Tag key={uuidv4()} info={item}/>)
                }
            </ul>
        </div>
    );
};

export default LegendTags;
