import {FilterContext} from "providers/FilterProvider";
import React, {useContext} from "react";
import {FilterOptionType} from "./Filter";

type FilterOptionsProps = {
    option: FilterOptionType
}

const FilterOption = ({option}: FilterOptionsProps) => {
    const {setCategories} = useContext(FilterContext);

    const handleChange = () => {
        setCategories(prevState => (prevState.map(state => (
            state.value === option.value ? {...state, checked: !state.checked} : state
        ))));
    };

    return (
        <label className="pochatok-im-options__label">
            <input
                checked={option.checked}
                onChange={handleChange}
                className="visible-hidden pochatok-im-options__checkbox"
                type="checkbox"
            />
            <span className="pochatok-im-options__indicator"/>
            <span>{option.label}</span>
        </label>
    );
};

export default FilterOption;
