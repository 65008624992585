import React from "react";

type LoaderType = {
    type?: "map" | "standard"
}

const Loader = ({type}: LoaderType) => {
    return (
        <div className={`pochatok-im-loader ${type}`}/>
    );
};

export default Loader;
