import {useRedirectSchema} from "hooks/useRedirectSchema";
import React from "react";
import {TargetsFishMarket} from "config/targets";
import {SchematicFishMarket} from "config/schematics";
import {SchemaType} from "./SchemaPochatok";

const SchemaFishMarket = ({elements}: SchemaType) => {
    const targets = useRedirectSchema(SchematicFishMarket, TargetsFishMarket);

    return (
        <div className="pochatok-im-schema pochatok-im-schema--fish-market">
            <svg width="570" height="675" viewBox="0 0 570 675" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M62 171.034H132V211H62V458H132V509H88V563H132V574H0V640H132V629H520V191H570V48H132V0H62V171.034Z" fill="#DAE5DF"/>
                <path d="M327 29V43" stroke="#93A39A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M334 36L327 43L320 36" stroke="#93A39A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>

                {elements.map(el => el.Element)}
                {targets.map(el => el.Element)}
            </svg>

            {elements.map(el => el.Tooltip)}
        </div>
    );
};

export default SchemaFishMarket;
