import React from "react";
import {SchemaType} from "./SchemaPochatok";

const SchemaPavilionUkraine = ({elements}: SchemaType) => {
    return (
        <div className="pochatok-im-schema pochatok-im-schema--pavilion-ukraine">
            <svg width="240" height="820" viewBox="0 0 240 820" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M40.0001 788L40 32L200 32L200 788H40.0001Z" fill="#DAE5DF"/>
                <rect x="152" y="32" width="16" height="756" fill="#DAE5DF"/>
                <g clipPath="url(#clip0_4028_47387)">
                    <rect x="136" y="32" width="16" height="756" fill="#F0F5F2"/>
                    <path d="M136 32L152 48" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 48L152 64" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 64L152 80" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 80L152 96" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 96L152 112" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 112L152 128" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 128L152 144" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 144L152 160" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 160L152 176" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 176L152 192" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 192L152 208" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 208L152 224" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 224L152 240" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 240L152 256" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 256L152 272" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 272L152 288" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 288L152 304" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 304L152 320" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 320L152 336" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 336L152 352" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 352L152 368" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 368L152 384" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 384L152 400" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 400L152 416" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 416L152 432" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 432L152 448" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 448L152 464" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 464L152 480" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 480L152 496" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 496L152 512" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 512L152 528" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 528L152 544" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 544L152 560" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 560L152 576" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 576L152 592" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 592L152 608" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 608L152 624" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 624L152 640" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 640L152 656" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 656L152 672" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 672L152 688" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 688L152 704" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 704L152 720" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 720L152 736" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 736L152 752" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 752L152 768" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M136 768L152 784" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                </g>
                <rect x="108" y="32" width="24" height="756" fill="#F0F5F2"/>
                <g clipPath="url(#clip1_4028_47387)">
                    <rect x="88" y="32" width="16" height="756" fill="#F0F5F2"/>
                    <path d="M88 32L104 48" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 48L104 64" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 64L104 80" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 80L104 96" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 96L104 112" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 112L104 128" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 128L104 144" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 144L104 160" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 160L104 176" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 176L104 192" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 192L104 208" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 208L104 224" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 224L104 240" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 240L104 256" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 256L104 272" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 272L104 288" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 288L104 304" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 304L104 320" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 320L104 336" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 336L104 352" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 352L104 368" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 368L104 384" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 384L104 400" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 400L104 416" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 416L104 432" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 432L104 448" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 448L104 464" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 464L104 480" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 480L104 496" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 496L104 512" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 512L104 528" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 528L104 544" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 544L104 560" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 560L104 576" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 576L104 592" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 592L104 608" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 608L104 624" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 624L104 640" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 640L104 656" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 656L104 672" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 672L104 688" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 688L104 704" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 704L104 720" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 720L104 736" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 736L104 752" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 752L104 768" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                    <path d="M88 768L104 784" stroke="#DAE5DF" strokeWidth="2" strokeLinecap="square"/>
                </g>
                <rect x="72" y="32" width="16" height="756" fill="#DAE5DF"/>

                {elements.map(el => el.Element)}


                <defs>
                    <clipPath id="clip0_4028_47387">
                        <rect width="16" height="756" fill="white" transform="translate(136 32)"/>
                    </clipPath>
                    <clipPath id="clip1_4028_47387">
                        <rect width="16" height="756" fill="white" transform="translate(88 32)"/>
                    </clipPath>
                </defs>
            </svg>

            {elements.map(el => el.Tooltip)}
        </div>
    );
};

export default SchemaPavilionUkraine;
