import React from "react";
import ReactDOM from "react-dom/client";
import App from "App";
import FilterProvider from "providers/FilterProvider";
import ApplicationProvider from "providers/ApplicationProvider";
import NavigationProvider from "providers/NavigationProvider";
import PageProvider from "./providers/PageProvider";
import "./i18n";
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(
    document.getElementById("pochatok-interactive-map") as HTMLElement
);

root.render(
    <BrowserRouter>
        <ApplicationProvider>
            <PageProvider>
                <NavigationProvider>
                    <FilterProvider>
                        <App/>
                    </FilterProvider>
                </NavigationProvider>
            </PageProvider>
        </ApplicationProvider>
    </BrowserRouter>
);
